import InstructorEmojiMessage from "components/EmojiMessage/InstructorEmojiMessage"
import { connect } from "react-redux"
import actions from "duck/actions"

const mapStateToProps = (state) => ({
  emoji: state.instructor.emojiMessage.emoji,
  expireTimestamp: state.instructor.emojiMessage.expireTimestamp,
  duration: state.instructor.emojiMessage.duration,
})

const mapDispatchToProps = (dispatch) => {
  return {
    endEmojiMessage: () => {
      dispatch(actions.instructor.endEmoji())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstructorEmojiMessage)
