/**
 *
 * @param {number} prevCalories
 * @param {number} currCalories
 * @param {Array<number>} calorieRewardValues
 */

export const shouldRewardByCalories = (
  prevCalories,
  currCalories,
  calorieRewardValues,
) => {
  let shouldRewardPoints = []
  if (!calorieRewardValues || calorieRewardValues.length === 0) {
    throw new Error(
      "calorieRewardValues is missing or blank in call to shouldRewardBytCalories",
    )
  }
  for (let i = 0; i < calorieRewardValues.length; i++) {
    if (
      prevCalories < currCalories &&
      prevCalories < calorieRewardValues[i] &&
      currCalories >= calorieRewardValues[i]
    ) {
      shouldRewardPoints.push(calorieRewardValues[i])
    }
  }
  const result = shouldRewardPoints.length === 1
  const rewardPoint = result ? shouldRewardPoints[0] : null
  return {
    result,
    rewardPoint,
  }
}
