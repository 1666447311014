import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { ReducerWithInitialState } from "@reduxjs/toolkit/dist/createReducer"
import { defaultMotif } from "./motifs"

export interface MotifState {
  selectedMotif: string
}

const initialState: MotifState = {
  selectedMotif: defaultMotif,
}

const motifSlice = createSlice({
  name: "motif",
  initialState,
  reducers: {
    selectMotifAction: (state, action: PayloadAction<string>) => {
      state.selectedMotif = action.payload
    },
  },
})

export const { selectMotifAction } = motifSlice.actions

export const motifReducer =
  motifSlice.reducer as ReducerWithInitialState<MotifState>

motifReducer.getInitialState = () => initialState
