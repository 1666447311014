import React from "react"
import "./MotifPickerModal.scss"
import { useDispatch, useSelector } from "react-redux"
import { getMotifPickerModalOpen } from "modules/ui/uiSelectors"
import { setMotifPickerModalOpen } from "modules/ui/uiSlice"
import { selectMotifAction } from "modules/motif/motifSlice"
import { getSelectedMotif } from "modules/motif/motifSelectors"
import { useMotifs } from "modules/motif/motifs"
import { useT } from "@transifex/react"

export const MotifPickerModal = () => {
  const t = useT()
  const selectedMotif = useSelector(getSelectedMotif)
  const motifPickerModalOpen = useSelector(getMotifPickerModalOpen)
  const motifs = useMotifs()
  const dispatch = useDispatch()
  const onSelectMotif = (motif: string) => dispatch(selectMotifAction(motif))
  const close = () => dispatch(setMotifPickerModalOpen(false))

  const onClick = (motif: string) => {
    onSelectMotif(motif)
    close()
  }

  return (
    <div
      className="MotifPickerOverlay"
      style={{
        visibility: motifPickerModalOpen ? "visible" : "hidden",
      }}
    >
      <div className="MotifPickerModal">
        <div className="ModalTitle">{t("Select channel")}</div>
        <ul>
          {motifs.map((motif) => (
            <li
              className={
                motif.id + (selectedMotif === motif.id ? " selected" : "")
              }
              onClick={() => onClick(motif.id)}
            >
              <div className="title">{motif.name}</div>
              <div className="description">{motif.description}</div>
            </li>
          ))}
        </ul>
        <div className="cancel">
          <a onClick={close}>{t("Cancel")}</a>
        </div>
      </div>
    </div>
  )
}
