import { combineReducers } from "redux"
import instructorReducer from "modules/instructor/duck/reducer"
import usersReducer from "modules/users/duck/reducer"
import { streamReducer } from "modules/stream/streamSlice"
import { deviceReducer } from "modules/device/deviceSlice"
import { flagsReducer } from "flags/flagsReducer"
import { gameReducer } from "modules/game/gameSlice"
import { ivsReducer } from "modules/ivs/ivsSlice"
import { motifReducer } from "modules/motif/motifSlice"
import { uiReducer } from "modules/ui/uiSlice"

const rootReducer = combineReducers({
  instructor: instructorReducer,
  users: usersReducer,
  device: deviceReducer,
  stream: streamReducer,
  flags: flagsReducer,
  game: gameReducer,
  ivs: ivsReducer,
  motif: motifReducer,
  ui: uiReducer,
})

export default rootReducer
