import React, { useEffect, useState } from "react"
import moment from "moment"
import { useT } from "@transifex/react"

/*
This element can be used in two modes.

# Without a duration (duration is zero):
This is a user casting a 'random' session to the display.
We don't know up front how long the class is going to be.

__We will be showing for how long the class has been going.__

# With a duration (duration is >zero):
This is a scheduled live or replay class, with a known
duration.
__We will show how much is left of the class.__
*/

const ClassTimer = ({ startTime, duration }) => {
  const t = useT()
  const [timeStr, setTime] = useState(0)

  const formatDuration = (diffTs) => {
    return moment.utc(diffTs).format("HH:mm:ss")
  }

  const withDuration = (ts, duration) => {
    const endTime = moment(ts).add(duration, "seconds").diff(moment())
    return Math.max(endTime, 0)
  }

  const withoutDuration = (startTs: Date) => {
    return moment().diff(startTs)
  }

  useEffect(() => {
    const ref = setInterval(() => {
      const ts =
        duration === 0
          ? withoutDuration(startTime)
          : withDuration(startTime, duration)
      setTime(ts)
    }, 200)

    return () => clearInterval(ref)
  })

  return (
    <div className="SessionTime">
      <div className="value">{formatDuration(timeStr)}</div>
      <div className="title">
        {duration !== 0 ? t("Time left") : t("Duration")}
      </div>
    </div>
  )
}

export default ClassTimer
