import React, { useState } from "react"
import defaultAvatar from "./../../assets/images/default-avatar.png"

const DEFAULT_AVATAR_COLORS = [
  "#767bff",
  "#75cfe3",
  "#5aacf4",
  "#e8617f",
  "#ffcd68",
  "#86c89a",
  "#7745ca",
  "#eea057",
  "#7abcaf",
  "#d67b61",
]

const ProfilePicture = ({ userId }) => {
  const index = (userId ?? 0) % 10
  const defaultBackgroundColor = DEFAULT_AVATAR_COLORS[index]
  const url = process.env.REACT_APP_PROFILE_PICTURE_BUCKET_URL
  const imageUrl = `${url}/${userId}/128x128.jpg`

  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <div className="ProfilePicture">
      <img
        style={{
          height: !imageLoaded ? 0 : undefined,
          width: !imageLoaded ? 0 : undefined,
        }}
        alt=""
        src={imageUrl}
        onLoad={() => setImageLoaded(true)}
      />
      {!imageLoaded && (
        <img
          alt=""
          src={defaultAvatar}
          style={{ backgroundColor: defaultBackgroundColor }}
        />
      )}
    </div>
  )
}

export default ProfilePicture
