const getInstructor = (state = null) => {
  if (state) {
    return state.instructor
  } else {
    return null
  }
}

export default {
  getInstructor,
}
