import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { ReducerWithInitialState } from "@reduxjs/toolkit/dist/createReducer"
import { Stream } from "models/Stream"

export interface StreamState {
  streams: Stream[]
}

const initialState: StreamState = {
  streams: [],
}

const streamSlice = createSlice({
  name: "stream",
  initialState,
  reducers: {
    fetchStreamsAction: (state) => {
      return state
    },
    newStreamsAction: (state, action: PayloadAction<Stream[]>) => {
      state.streams = action.payload
    },
    currentStreamChangedAction() {},
    currentStreamStartedAction() {},
  },
})

export const {
  fetchStreamsAction,
  newStreamsAction,
  currentStreamChangedAction,
  currentStreamStartedAction,
} = streamSlice.actions

export const streamReducer =
  streamSlice.reducer as ReducerWithInitialState<StreamState>
streamReducer.getInitialState = () => initialState
