import flagsmith from "flagsmith"
import { EventChannel, eventChannel } from "redux-saga"
import { Flags, flagDefinitions } from "./flags"
import { mapValues } from "lodash"
import { call, fork, put, take } from "redux-saga/effects"
import { setFlagsAction } from "./flagsActions"

type Environment = "production" | "development"

// listen for flags once every 2 hours (we don't want to exceed quota)
const FETCH_FLAGS_INTERVAL = 2 * 60 * 60 * 1000

const getFlagsmithEnvironmentId = (envioronment: Environment): string => {
  if (envioronment === "production") {
    return "ngG8wKLJ9cusBzdzCpT3EQ"
  }
  return "MvRmaoTPPJemHwPyRbzxu6"
}

function createFlagsChannel(environment: Environment) {
  return eventChannel<Flags>((emit) => {
    flagsmith.init({
      environmentID: getFlagsmithEnvironmentId(environment),
      preventFetch: true,
      onChange: () => {
        const newFlags = mapValues(flagDefinitions, (_, flagName) => ({
          enabled: flagsmith.hasFeature(flagName),
          value: flagsmith.getValue(flagName),
        }))
        emit(newFlags)
      },
    })

    return flagsmith.stopListening
  })
}

export function* flagsSaga() {
  const envioronment: Environment =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "production"
      : "development"

  const flagChannel = yield call(createFlagsChannel, envioronment)
  yield fork(monitorFlags, flagChannel)
  getFlagsAndStartListening()
}

function* monitorFlags(channel: EventChannel<Flags>) {
  try {
    while (true) {
      const flags = yield take(channel)
      yield put(setFlagsAction(flags))
    }
  } finally {
    channel.close()
  }
}

function getFlagsAndStartListening() {
  flagsmith.getFlags()
  flagsmith.startListening(FETCH_FLAGS_INTERVAL)
}
