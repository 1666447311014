import { delay, call, select } from "redux-saga/effects"
import api from "api"
import { getNumberOfUsers } from "modules/device/selectors"
import { getSelectedMotif } from "modules/motif/motifSelectors"

/**
 * We are trying to make the team display as current as possible
 * by reloading every so often.
 *
 * 1. Every few minutes we check if the current build version matches
 *    the one served by the amplify server.
 *    If there are no current users, reload.
 * 2. If we catch 3 exceptions reload.
 *
 */
export default function* checkBuildSaga() {
  let failedAttempts = 0
  while (true) {
    try {
      // Wait 4 minutes plus jitter.
      yield delay(240 * 1000 + Math.floor(Math.random() * 10 * 1000))
      // Compare local and server versions.
      const localVersion = process.env.REACT_APP_AWS_JOB_ID.trim()
      const serverVersion = yield call(api.get_version)
      const branch = process.env.REACT_APP_AWS_BRANCH

      const hasUpdate = serverVersion !== localVersion
      const hasActiveUsers = yield select(getNumberOfUsers) > 0
      const selectedMotif = yield select(getSelectedMotif)

      // see https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress.
      const reload = hasUpdate && !hasActiveUsers && !window.Cypress
      // Report versions to the server
      yield call(
        api.reportCheckbuild,
        branch,
        localVersion,
        serverVersion,
        reload,
        selectedMotif,
      )

      // Reload window if applicable
      failedAttempts = 0
      if (reload) {
        window.location.reload()
      }
    } catch {
      failedAttempts++
    } finally {
      if (failedAttempts > 3) {
        window.location.reload()
      }
    }
  }
}
