import types from "duck/types"

const countDownClass = (payload) => {
  return {
    type: types.instructor.COUNT_DOWN_CLASS,
    payload,
  }
}

const countdownSegment = (payload) => {
  return {
    type: types.instructor.COUNT_DOWN_SEGMENT,
    payload,
  }
}

const getNewInstructor = (payload) => {
  return {
    type: types.instructor.GET_NEW_INSTRUCTOR,
    payload,
  }
}

const playEmoji = (payload) => {
  return {
    type: types.instructor.PLAY_EMOJI,
    payload,
  }
}

function endCountdown() {
  return {
    type: types.instructor.END_COUNTDOWN,
  }
}

function endEmoji() {
  return {
    type: types.instructor.END_EMOJI,
  }
}

const getMessage = (payload) => {
  return { type: types.instructor.GET_MESSAGE, payload }
}

function endSegment() {
  return {
    type: types.instructor.END_SEGMENT,
  }
}

const resetClassTimer = () => {
  return { type: types.instructor.RESET_CLASS_TIMER }
}

export default {
  countDownClass,
  countdownSegment,
  getNewInstructor,
  playEmoji,
  endCountdown,
  endEmoji,
  getMessage,
  endSegment,
  resetClassTimer,
}
