import { delay, fork } from "redux-saga/effects"
import checkBuildSaga from "./checkBuildSaga"

function* start() {
  try {
    const cronTasks = [checkBuildSaga]
    for (let i = 0; i < cronTasks.length; i++) {
      yield fork(cronTasks[i])
    }
  } catch (e) {
    // Honestly, this TD is in a bad state.
    // Retry and hope for the best.
    yield delay(10 * 1000)
    window.location.reload()
  }
}
export default { start }
