import { getSelectedMotif } from "modules/motif/motifSelectors"
import { selectStreamForMotifAndDate } from "modules/motif/motifs"
import moment from "moment"
import { createSelector } from "reselect"
import { State } from "state/store"
import { getLocalizedWorkoutLanguage } from "tools/utils"

const getStreams = (state: State) => state.stream.streams

// Note: selectors should be pure functions and should not call moment().
export const currentStreamSelector = createSelector(
  [getStreams, getSelectedMotif],
  (streams, selectedMotif) =>
    selectStreamForMotifAndDate(streams, selectedMotif, moment()),
)

export const streamLocalizedWorkoutLanguage = createSelector(
  currentStreamSelector,
  (stream) => stream && getLocalizedWorkoutLanguage(stream.workout_locale),
)

export const isReplaySelector = createSelector(
  currentStreamSelector,
  (stream) => stream?.operator !== "star",
)

export const streamStartTimeSelector = createSelector(
  currentStreamSelector,
  (stream) => stream && moment(stream.start_utc_iso_datetime),
)

export const streamDurationSelector = createSelector(
  currentStreamSelector,
  (stream) => stream && stream.duration_s,
)

export const replayStartTimestampSelector = createSelector(
  currentStreamSelector,
  (stream) => stream && stream.details.ivs_recording?.stream_start_ts,
)

export const replayEndTimestampSelector = createSelector(
  currentStreamSelector,
  (stream) => stream && stream.details.ivs_recording?.stream_end_ts,
)

export const streamPlaybackUrlSelector = createSelector(
  currentStreamSelector,
  isReplaySelector,
  (currentStream, isReplay) =>
    isReplay
      ? currentStream?.details.ivs_recording?.stream_url
      : currentStream?.details.ivs_channel?.playbackUrl,
)

export const streamEndTimeSelector = createSelector(
  currentStreamSelector,
  (currentStream) =>
    moment(currentStream?.start_utc_iso_datetime).add(
      currentStream?.duration_s,
      "seconds",
    ),
)
