import React from "react"
import { getMotosumoTime } from "tools/time"
import emojiUtils from "components/EmojiMessage/utils"
import "./InstructorEmojiMessage.scss"

export default class EmojiMessage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { remaining: 0, animation: false }
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, 50)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.animation === false && this.state.animation === true) {
      this.leaveTimer = setTimeout(this.props.endEmojiMessage, 500)
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer)
    clearTimeout(this.leaveTimer)
  }

  tick = () => {
    if (this.props.expireTimestamp !== null) {
      let remaining = this.props.expireTimestamp - getMotosumoTime() // miliseconds
      let leave = false
      if (remaining < 0) {
        leave = true
      }
      this.setState({ remaining: remaining, animation: leave })
    }
  }

  render() {
    let leaving = ["message"]
    if (this.state.animation) {
      leaving.push("leaving")
    }
    if (this.props.emoji) {
      return (
        <div className="InstructorEmojiMessage">
          <div className={leaving.join(" ")}>
            {this.props.emoji
              .split(":")
              .map((emoji, i) =>
                emojiUtils.isExisting(emoji) ? (
                  <div key={i} className={"img " + emoji} />
                ) : (
                  <div key={i} className={"img _404"} />
                ),
              )}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}
