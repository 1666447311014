import { connect } from "react-redux"
import BrowserCompatGate from "components/BrowserCompatGate"

const mapStateToProps = (state) => {
  return {
    browserCompactWarningIgnored: {},
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ignoreBrowserCompactWarning: () => {},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrowserCompatGate)
