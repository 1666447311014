import { createSelector } from "reselect"
import {
  currentStreamSelector,
  streamDurationSelector,
  streamStartTimeSelector,
} from "modules/stream/streamSelectors"
import { State } from "state/store"

export const getDeviceToken = (state: State) => {
  return state.device.device_token
}

const getGroupCode = (state: State) => {
  return state.device.group_code
}

const getGroupName = (state: State) => {
  return state.device.group_name
}

const getGroupStartTime = (state: State) => {
  return state.device.start_time
}

const getGroupDuration = (state: State) => {
  return state.device.duration
}

export const groupInfoSelector = createSelector(
  getGroupCode,
  getGroupName,
  getGroupStartTime,
  getGroupDuration,
  streamStartTimeSelector,
  streamDurationSelector,
  (
    groupCode,
    groupName,
    groupStartTime,
    groupDuration,
    streamStartTime,
    streamDuration,
  ) => {
    if (groupCode !== undefined) {
      return {
        code: groupCode,
        name: groupName,
        start_time: groupStartTime,
        duration: groupDuration,
      }
    } else {
      return {
        start_time: streamStartTime,
        duration: streamDuration,
      }
    }
  },
)

export const getGroupCastingId = (state: State) => {
  return state.device.group_id
}

export const getIsCasting = (state: State) => {
  return state.device.group_id !== undefined
}

export const hasLiveStreamSelector = createSelector(
  getIsCasting,
  (isCasting) => {
    return !isCasting
  },
)

export const getClassStats = (state: State) => {
  return state.device.class_stats
}

export const getChannelId = createSelector(
  getGroupCastingId,
  currentStreamSelector,
  (groupCastingId, currentStream) => {
    if (groupCastingId !== undefined) {
      return groupCastingId
    } else {
      return currentStream?.stream_uuid
    }
  },
)

export const getModeIndex = (state: State) => state.device.mode_index

export const getModeDisplayValue = createSelector(getModeIndex, (modeIndex) => {
  switch (modeIndex) {
    case 0:
      return "cadence"
    case 1:
      return "heartrate_percent"
    case 2:
      return "power_percent"
    default:
      return "cadence"
  }
})

export const getNumberOfUsers = (state: State) => {
  return state.device.class_stats?.total_number_of_users ?? 0
}
