import createSagaMiddleware from "redux-saga"
import sagas from "duck/sagas"
import { recurringTask } from "tools/utils"
import { flagsSaga } from "flags/flagsSaga"
import { ivsSaga } from "modules/ivs/ivsSagas"
import {
  fetchStreamsSaga,
  monitorStreamsSaga,
  monitorCurrentStreamStartSaga,
} from "modules/stream/streamSagas"
import {
  deviceChannelSaga,
  joinGroupChannelSaga,
  monitorCurrentStreamSaga,
  monitorGroupCastMessagesSaga,
  watchDeviceTokenChannelSaga,
} from "modules/device/deviceSagas"
import { localeSaga } from "modules/locale/localeSagas"

export const sagaMiddleware = createSagaMiddleware()

export function runSagas() {
  sagaMiddleware.run(sagas.cron.start)

  sagaMiddleware.run(flagsSaga)
  sagaMiddleware.run(localeSaga)

  sagaMiddleware.run(monitorGroupCastMessagesSaga)
  sagaMiddleware.run(deviceChannelSaga)
  sagaMiddleware.run(watchDeviceTokenChannelSaga)
  sagaMiddleware.run(joinGroupChannelSaga)
  sagaMiddleware.run(monitorCurrentStreamSaga)
  sagaMiddleware.run(sagas.games.watchJoinGameChannelSaga)

  // streamsSagas
  sagaMiddleware.run(fetchStreamsSaga)
  sagaMiddleware.run(monitorStreamsSaga)
  sagaMiddleware.run(monitorCurrentStreamStartSaga)

  // ivsSagas
  sagaMiddleware.run(ivsSaga)

  // start time sync in the beginning of the app loading
  sagaMiddleware.run(recurringTask, sagas.time.startRestTimeSync, 30)

  sagaMiddleware.run(sagas.instructor.messageWatcher)
}
