export enum GameRunningState {
  None = "none",
  Available = "available",
  Running = "running",
  Finished = "finished",
}

export type GameType = "racing_legends"
export type GameUnits = "meters" | "yards"

//
// Statsworker data model
//
interface GameChannelPresence {
  online_at: string
  team_name: string
  session_id: number
}

export interface GameChannelUser {
  id: number
  metas: Array<GameChannelPresence>
  nickname: string
}

export interface GameChannelPresenceState {
  [user_id: number]: GameChannelUser
}

export interface GameChannelPresenceDiff {
  joins: GameChannelPresenceState
  leaves: GameChannelPresenceState
}

export interface GameAvailable {
  duration?: number
  game_id?: string
  type: GameType
  units: GameUnits
}

export interface GameStart {
  duration: number
  game_id: string
  game_stop_ts: number
}

export interface GameUser {
  user_id: number
  session_id: number
  nickname: string
  team_name: string
}

export interface GameSessionMap {
  [session_id: number]: GameUser
}
export interface GameScoreMap {
  [session_id: number]: number
}

export interface GameUpdate {
  team_a_score: number
  team_a_scores: GameScoreMap
  team_a_speed_total: number
  team_b_score: number
  team_b_scores: GameScoreMap
  team_b_speed_total: number
  units: GameUnits
}
