import { GameUnits } from "modules/game/gameTypes"
import { useEffect, useState } from "react"
import { UnityContent } from "react-unity-webgl"

export type UnityTeamName = "team_a" | "team_b"
export interface UnityGameConfig {
  duration: number
  unit: GameUnits
}

export interface UnityTeamMember {
  nickname: string
  user_id: number
  score: number
  team_name: UnityTeamName
}

export interface UnityTeam {
  score: number
  speed: number
  users: UnityTeamMember[]
}

export interface UnityGameData {
  team1: UnityTeam
  team2: UnityTeam
}

export const UNITY_CONTENT = new UnityContent(
  "assets/racing3d/webglbuild.json",
  "assets/racing3d/UnityLoader.js",
)

export const unitySendGameConfig = (config: UnityGameConfig) => {
  UNITY_CONTENT.send(
    "GameController",
    "SetupGameFromReact",
    JSON.stringify(config),
  )
}

export const unitySendGameData = (data: UnityGameData) => {
  UNITY_CONTENT.send("GameController", "SetDataFromReact", JSON.stringify(data))
}

export const unitySendGameResults = (data: UnityGameData) => {
  UNITY_CONTENT.send(
    "GameController",
    "SetFinalFromReact",
    JSON.stringify(data),
  )
}

export const unityResetGame = () => {
  UNITY_CONTENT.send("GameController", "ResetGameFromReact")
}

export const useGameIsReady = () => {
  const [gameIsReady, setGameIsReady] = useState(false)

  useEffect(() => {
    UNITY_CONTENT.on("GameReady", () => {
      setGameIsReady(true)
    })
    // Note that we would normally return a callback here to remove the listener
    // from the UnityContent instance, but since 1) UnityContent doesn't have a
    // documented way to remove listeners and 2) the Unity component is always
    // mounted (to ensure assets are re-loaded before a game starts), we never
    // actually remove the listener here.
  }, [])

  return gameIsReady
}
