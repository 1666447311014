import { InstructorMessageOut } from "models/InstructorMessageOut"

type InstructorIvsMetadata = {
  type: string
  payload: InstructorMessageOut
}

/**
 * Serialize outgoing instructor IVS metadata to a string using JSON.stringify.
 * @param message
 * @returns
 */
export const serializeInstructorIvsMetadata = (
  message: InstructorMessageOut,
): string => {
  return JSON.stringify({
    type: "timer",
    payload: message,
  })
}

/**
 * Defensive deserialization of incoming IVS instructor metadata using
 * JSON parsing and validation of required fields.
 * @param payload
 * @returns
 */
export const deserializeInstructorIvsMetadata = (
  payload: string,
): InstructorIvsMetadata | undefined => {
  try {
    const metadata = JSON.parse(payload)
    if (
      Object.hasOwnProperty.call(metadata, "type") &&
      Object.hasOwnProperty.call(metadata, "payload")
    ) {
      return metadata
    }
  } catch (error) {
    console.error(error)
  }
}
