import React from "react"
import "./UserEmojiMsg.scss"
import emojiUtils from "components/EmojiMessage/utils"

const UserEmojiMsg = ({ emojiMsg: { emoji } }) => {
  const emojiFileName = emojiUtils.isExisting(emoji) ? emoji : "_404"
  const emoji_src = require(
    `components/EmojiMessage/emojione/${emojiFileName}.png`,
  )
  return (
    <div className="user-emoji-cell">
      <img
        className="user-emoji-cell__emoji"
        src={emoji_src}
        alt="user message emoji"
      />
    </div>
  )
}

export default UserEmojiMsg
