const COUNT_DOWN_CLASS = "instructor/COUNT_DOWN_CLASS"
const COUNT_DOWN_SEGMENT = "instructor/COUNT_DOWN_SEGMENT"
const GET_NEW_INSTRUCTOR = "instructor/GET_NEW_INSTRUCTOR"
const PLAY_EMOJI = "instructor/PLAY_EMOJI"
const END_EMOJI = "instructor/END_EMOJI"
const END_COUNTDOWN = "instructor/END_COUNTDOWN"
const END_SEGMENT = "instructor/END_SEGMENT"
const GET_MESSAGE = "instructor/GET_MESSAGE"
const RESET_CLASS_TIMER = "instructor/RESET_CLASS_TIMER"

export default {
  COUNT_DOWN_CLASS,
  COUNT_DOWN_SEGMENT,
  GET_NEW_INSTRUCTOR,
  PLAY_EMOJI,
  END_EMOJI,
  END_COUNTDOWN,
  END_SEGMENT,
  GET_MESSAGE,
  RESET_CLASS_TIMER,
}
