import { take, put, select } from "redux-saga/effects"
import actions from "duck/actions"
import types from "duck/types"
import { getIsCasting } from "modules/device/selectors"

/**
 * listen GET_MESSAGE and interpret message
 */
function* messageWatcher() {
  while (true) {
    const action = yield take(types.instructor.GET_MESSAGE)
    yield interpret(action)
  }
}

function* interpret({ payload }) {
  if (payload.hasOwnProperty("countdown")) {
    // Only show intructor emojis from statsworker when casting,
    // when playing video they should only come from IVS.
    const isCasting = yield select(getIsCasting)
    if (isCasting) {
      yield put(actions.instructor.countDownClass(payload))
    }
  } else if (payload.hasOwnProperty("segment_countdown")) {
    if (payload.segment_countdown !== "cancel") {
      yield put(actions.instructor.countdownSegment(payload))
    } else {
      yield put(actions.instructor.endSegment())
    }
  } else if (payload.hasOwnProperty("new_instructor")) {
    yield put(actions.instructor.getNewInstructor(payload))
  } else if (payload.hasOwnProperty("emoji")) {
    // Only show intructor emojis from statsworker when casting,
    // when playing video they should only come from IVS.
    const isCasting = yield select(getIsCasting)
    if (isCasting) {
      yield put(actions.instructor.playEmoji(payload))
    }
  } else if (payload.hasOwnProperty("clear_class")) {
    yield put(actions.instructor.resetClassTimer())
  } else {
    console.log("unknown instructor message")
  }
}

export default {
  messageWatcher: messageWatcher,
}
