import { create, isPlayerSupported, MediaPlayer } from "amazon-ivs-player"

// We use the TypeScript compiler (TSC) to check types; it doesn't know what this WASM module is, so let's ignore the error it throws (TS2307).
// @ts-ignore
import wasmBinaryPath from "amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm"
import wasmWorkerPath from "amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js"

let IVS_PLAYER: MediaPlayer | undefined

if (!isPlayerSupported) {
  throw new Error("IVS Player is not supported in this browser")
}

const createAbsolutePath = (assetPath: string) =>
  new URL(assetPath, document.URL).toString()

export const createIvsPlayer = () => {
  IVS_PLAYER = create({
    wasmWorker: createAbsolutePath(wasmWorkerPath),
    wasmBinary: createAbsolutePath(wasmBinaryPath),
  })

  IVS_PLAYER.setAutoplay(false)
  IVS_PLAYER.setLiveLowLatencyEnabled(true)
  IVS_PLAYER.setRebufferToLive(true)

  return IVS_PLAYER
}

export const deleteIvsPlayer = () => {
  IVS_PLAYER?.delete()
  IVS_PLAYER = undefined
}

export const getIvsPlayer = () => {
  return IVS_PLAYER
}

export const getIvsVideoElement = () => {
  return document.querySelector("video")
}
