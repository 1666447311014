export type DeviceMode = "CADENCE" | "HEARTRATE" | "POWER"

export type DeviceModeUpdate = {
  token: string
  mode: DeviceMode
}

export const modeToIndex = (mode: DeviceMode) => {
  switch (mode) {
    case "CADENCE":
      return 0
    case "HEARTRATE":
      return 1
    case "POWER":
      return 2
    default:
      return 0
  }
}
