import { Stream } from "models/Stream"
import { Moment } from "moment"
import { getMotosumoTime } from "tools/time"

/**
 * The stream progress is the time in seconds since the scheduled start
 * of the stream. If the stream has not started yet, the stream progress
 * will be negative.
 */
export const calculateStreamProgress = (streamStartTime: Moment): number => {
  const progressInMs = getMotosumoTime() - streamStartTime.valueOf()
  return Math.floor(progressInMs / 1000)
}

/**
 * The video progress is the current time of the HTML video element.
 * This should always match the replay progress as closely as possible.
 * The video progress is changed by calling seekTo(). We do this when
 * the IVS replaySaga starts.
 */
export const calculateVideoProgress = (
  streamStartTime: Moment,
  replayStartTimestamp: number,
): number => {
  const streamProgress = calculateStreamProgress(streamStartTime)
  return streamProgress + replayStartTimestamp
}

export const getStarImage = (stream: Stream | undefined) => {
  if (stream === undefined || stream.star === undefined) {
    return undefined
  }
  const largeNumber = parseInt(stream.stream_uuid.substring(0, 8), 16)
  const randomIndex = largeNumber % stream.star.images.length
  return stream.star.images[randomIndex]
}
