import types from "duck/types"
import { getMotosumoTime } from "tools/time"

const initialState = {
  countdownTimer: {
    shouldShow: false,
    countdown: 0,
    expire_time: 0,
  },
  segmentTimer: {
    shouldShow: false,
    countdown: 0,
    expire_timestamp: 0,
  },
  emojiMessage: {
    emoji: null,
    expireTimestamp: null,
    duration: 0,
  },
  soundEffect: {
    type: "",
  },
}

const instructorReducer = (
  state = initialState,
  action = { type: "", payload: {} },
) => {
  switch (action.type) {
    case types.instructor.COUNT_DOWN_CLASS: {
      const expire_time = action.payload.expire_timestamp
      const countdown = action.payload.countdown
      const color = action.payload.color
      const shouldShow = true
      return {
        ...state,
        countdownTimer: {
          shouldShow,
          expire_time,
          countdown,
          color,
        },
      }
    }
    case types.instructor.COUNT_DOWN_SEGMENT: {
      const expire_timestamp = action.payload.expire_timestamp
      const countdown = action.payload.segment_countdown
      return {
        ...state,
        segmentTimer: {
          shouldShow: true,
          countdown,
          expire_timestamp,
        },
      }
    }
    case types.instructor.GET_NEW_INSTRUCTOR: {
      return {
        ...state,
        id: action.payload.new_instructor,
      }
    }
    case types.instructor.PLAY_EMOJI: {
      const expireTimestamp = getMotosumoTime() + action.payload.duration
      const emoji = action.payload.emoji
      return {
        ...state,
        emojiMessage: { expireTimestamp, emoji },
      }
    }
    case types.instructor.END_EMOJI: {
      return {
        ...state,
        emojiMessage: {
          emoji: null,
          expireTimestamp: null,
          duration: 0,
        },
      }
    }
    case types.instructor.END_COUNTDOWN: {
      return {
        ...state,
        countdownTimer: {
          shouldShow: false,
          expire_time: 0,
          countdown: 0,
        },
      }
    }
    case types.instructor.END_SEGMENT: {
      return {
        ...state,
        segmentTimer: initialState.segmentTimer,
      }
    }
    default: {
      return state
    }
  }
}

export default instructorReducer
