import { Channel } from "phoenix"
import { call, take, takeLatest, fork } from "redux-saga/effects"
import {
  connectToChannel,
  waitForMessages,
  waitForSocketConnection,
} from "socket"
import {
  gameAvailableAction,
  gameEndedAction,
  gameFinishedAction,
  gamePresenceDiffAction,
  gamePresenceStateAction,
  gameStartedAction,
  gameUpdatedAction,
} from "./gameSlice"

function* joinGameChannelSaga(action: ReturnType<typeof gameAvailableAction>) {
  let channel: Channel | undefined
  try {
    if (!action.payload?.game_id) return

    const gameChannelDef = "game:" + action.payload.game_id

    channel = yield call(
      connectToChannel,
      yield call(waitForSocketConnection),
      gameChannelDef,
      {},
    )

    if (channel === undefined) {
      return
    }

    yield fork(waitForMessages, channel, "started", gameStartedAction)

    yield fork(
      waitForMessages,
      channel,
      "presence_state",
      gamePresenceStateAction,
    )
    yield fork(
      waitForMessages,
      channel,
      "presence_diff",
      gamePresenceDiffAction,
    )
    yield fork(waitForMessages, channel, "updated", gameUpdatedAction)
    yield fork(waitForMessages, channel, "finished", gameFinishedAction)
    yield fork(waitForMessages, channel, "ended", gameEndedAction)

    yield take(gameEndedAction)
  } catch (error) {
    console.log(error)
  } finally {
    channel?.leave()
  }
}

export function* watchJoinGameChannelSaga() {
  yield takeLatest(gameAvailableAction, joinGameChannelSaga)
}

const exports = {
  watchJoinGameChannelSaga,
}

export default exports
