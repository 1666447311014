import React, { Component } from "react"

class CountdownIcon extends Component {
  render() {
    const iconClassname = this.props.showIcon ? "icon" : undefined
    return <div className={iconClassname} />
  }
}

export default CountdownIcon
