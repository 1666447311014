import rootReducer from "duck/reducer"
import { sagaMiddleware, runSagas } from "./sagaMiddleware"
import { logger as reduxLogger } from "redux-logger"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { configureStore } from "@reduxjs/toolkit"
import { StreamState } from "modules/stream/streamSlice"
import { FlagsState } from "flags/flagsReducer"
import { GameState } from "modules/game/gameSlice"
import { DeviceState } from "modules/device/deviceSlice"
import { IvsState } from "modules/ivs/ivsSlice"
import { MotifState } from "modules/motif/motifSlice"
import { UIState } from "modules/ui/uiSlice"

export interface State {
  flags: FlagsState
  game: GameState
  stream: StreamState
  device: DeviceState
  ivs: IvsState
  motif: MotifState
  ui: UIState
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["motif"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let middleware = [sagaMiddleware, reduxLogger]

// Don't use redux logger in production and testing environments
if (
  process.env.REACT_APP_ENVIRONMENT === "production" ||
  process.env.NODE_ENV === "test"
) {
  middleware = [sagaMiddleware]
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: middleware as any,
})

const persister = persistStore(store, {}, () => {
  runSagas()
})

// expose store when run in Cypress
// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store
}

export { persister }
export default store
