import React, { useCallback, useEffect, useState } from "react"
import "./WaitingForClassScreen.scss"
import logo from "./../../assets/images/logo-horizontal.png"
import qr from "./../../assets/images/motosumo_qr.png"
import appStoreButton from "./../../assets/images/app-store-button.png"
import googlePlayButton from "./../../assets/images/google-play-button.png"
import Countdown from "components/Countdown"
import { bind } from "mousetrap"
import { getIsCasting } from "modules/device/selectors"
import { useSelector } from "react-redux"
import {
  currentStreamSelector,
  streamEndTimeSelector,
  streamLocalizedWorkoutLanguage,
  streamStartTimeSelector,
} from "modules/stream/streamSelectors"
import { useT } from "@transifex/react"
import { getFlags } from "flags/flagsSelectors"
import { getStarImage } from "modules/stream/streamUtils"
import moment from "moment"

const WaitingForClassScreen = () => {
  const t = useT()
  const flags = useSelector(getFlags)

  const localizedWorkoutLanguage = useSelector(streamLocalizedWorkoutLanguage)

  const streamStartTime = useSelector(streamStartTimeSelector)
  const streamEndTime = useSelector(streamEndTimeSelector)
  const isCasting = useSelector(getIsCasting)

  const [showWaitingScreen, setShowWaitingScreen] = useState(true)

  const currentStream = useSelector(currentStreamSelector)
  const star = currentStream?.star

  // Allow showing or hiding waiting screen by key binding, for debugging.
  bind("command+shift+alt+w", () => {
    setShowWaitingScreen(!showWaitingScreen)
  })

  const streamIsOngoing = useCallback(() => {
    return moment().isBetween(streamStartTime, streamEndTime)
  }, [streamStartTime, streamEndTime])

  useEffect(() => {
    const interval = setInterval(() => {
      setShowWaitingScreen(!isCasting && !streamIsOngoing())
    }, 1000)
    return () => clearInterval(interval)
  }, [isCasting, streamIsOngoing])

  if (!showWaitingScreen) {
    return null
  }

  if (currentStream === undefined || star === undefined) {
    return (
      <div className="NoClassScreen">
        <img className="NoClassMotosumoLogo" alt="Motosumo" src={logo} />
      </div>
    )
  }

  const isLive = currentStream.operator === "star"
  const starImage = getStarImage(currentStream)

  return (
    <div
      className="WaitingForClassScreen"
      style={{ backgroundColor: star.color }}
    >
      <div className="StarContainer">
        <div className="StarCircle">
          <img src={starImage} alt="" />
        </div>
        {isLive ? (
          <div className="LiveLabel">{t("LIVE")}</div>
        ) : (
          <div className="RePlayLabel">{t("RePlay")}</div>
        )}
        <div className="GetMotosumo">
          <div className="MotosumoQR">
            <img alt="QR code" src={qr} />
          </div>
          <div className="StoreButtons">
            <img alt="App Store" src={appStoreButton} />
            <img alt="Google Play" src={googlePlayButton} />
          </div>
        </div>
      </div>
      <div className="ClassContainer">
        <img className="MotosumoLogo" alt="logo" src={logo} />
        <div className="CountdownContainer">
          <Countdown
            streamStartTime={currentStream.start_utc_iso_datetime}
            color={star.color}
          />
        </div>
        <div className="ClassInfoContainer">
          <div className="ClassLabelContainer">
            <div>{localizedWorkoutLanguage}</div>
            <div>{(currentStream.duration_s ?? 0) / 60} min</div>
          </div>
          <div className="ClassTitle">{currentStream.concept.title}</div>
          <div className="StarName">
            {t("with")} {star.name}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WaitingForClassScreen
