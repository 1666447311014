import { tx } from "@transifex/native"
import { call } from "typed-redux-saga"
import api from "api"

tx.init({ token: "1/3086b3144fbe251b1974683f4681ab463c2ef867" })

export function* localeSaga() {
  const suggestedLocale = yield* call(api.suggestLocale)
  yield* call([tx, "setCurrentLocale"], suggestedLocale)
}
