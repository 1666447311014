import React from "react"
import "./style.scss"

class NotSupportedPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
      iconFontSize: "calc(1vw + 1vh)",
    }
  }

  timer = {
    hide: null,
  }

  clearTimer = () => {
    if (this.timer.hide) {
      window.clearTimeout(this.timer.hide)
      this.timer.hide = null
    }
  }

  hide = (e) => {
    e.preventDefault()
    this.setState({
      show: false,
    })
    this.timer.hide = setTimeout(() => {
      this.props.ignoreBrowserCompactWarning(e)
    }, 500)
  }

  getElementFontSize = (className) => {
    let node = document.getElementsByClassName(className)[0]
    if (node) {
      return window.getComputedStyle(node).getPropertyValue("font-size")
    } else {
      return "calc(1vw + 1vh)"
    }
  }

  updateIconFontSize = () => {
    const fontSize = this.getElementFontSize("info__alert-message")
    this.setState({ ...this.state, iconFontSize: fontSize })
  }
  render() {
    return (
      <div
        className={`notSupportedPage ${
          this.state.show ? "showNotSupportedPage" : ""
        }`}
      >
        <div className="info">
          <div className="info__alert">
            <span className="info__alert-message">{this.props.message}</span>
          </div>
          <span className="info__suggestion">{this.props.suggestion}</span>
          {this.props.link && (
            <span className="info__download_chrome">
              <a
                href={this.props.link.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.link.text}
              </a>
            </span>
          )}
          <span className="info__continue_anyway">
            <a href="./" onClick={this.hide}>
              Continue anyway
            </a>
          </span>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.updateIconFontSize()
    window.addEventListener("resize", this.updateIconFontSize)
  }

  componentWillUnmount() {
    this.clearTimer()
    window.removeEventListener("resize", this.updateIconFontSize)
  }
}

export default NotSupportedPage
