import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { ReducerWithInitialState } from "@reduxjs/toolkit/dist/createReducer"

export interface UIState {
  motifPickerModalOpen: boolean
}

const initialState: UIState = {
  motifPickerModalOpen: false,
}

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setMotifPickerModalOpen: (state, action: PayloadAction<boolean>) => {
      state.motifPickerModalOpen = action.payload
    },
  },
})

export const { setMotifPickerModalOpen } = uiSlice.actions

export const uiReducer = uiSlice.reducer as ReducerWithInitialState<UIState>
uiReducer.getInitialState = () => initialState
