import React from "react"
import MotosumoLogo from "./MotosumoLogo"
import MemberBox from "./MemberBox"
import TeamCalorieBox from "./TeamCalorieBox"
import ClassTimer from "./ClassTimer"
import "./header.scss"
import GroupCode from "./GroupCode"
import { useSelector } from "react-redux"
import { groupInfoSelector, getClassStats } from "modules/device/selectors"
import { currentStreamSelector } from "modules/stream/streamSelectors"
import { useT } from "@transifex/react"
import { getStarImage } from "modules/stream/streamUtils"

export const Header = () => {
  const t = useT()
  const currentStream = useSelector(currentStreamSelector)
  const classStats = useSelector(getClassStats)
  const groupInfo = useSelector(groupInfoSelector)

  const starImage = getStarImage(currentStream)
  const isInGroup = groupInfo.code !== undefined

  return (
    <header className="header">
      <div className="LogoBox">
        <MotosumoLogo />
      </div>
      {currentStream !== undefined && !isInGroup && (
        <div className="ClassDetails">
          {starImage !== undefined && (
            <div
              className="StarImage"
              style={{ backgroundColor: currentStream.star.color }}
            >
              <img src={starImage} alt="" />
            </div>
          )}
          <div>
            <div className="class">{currentStream.concept.title}</div>
            <div className="star">
              {t("with {star}", { star: currentStream.star.name })}
            </div>
          </div>
        </div>
      )}
      {isInGroup && (
        <GroupCode groupCode={groupInfo.code} groupName={groupInfo.name} />
      )}
      <MemberBox currentUserNumber={classStats?.active_number_of_users ?? 0} />
      <TeamCalorieBox teamCalorie={classStats?.total_calories} />
      {groupInfo && (
        <ClassTimer
          startTime={groupInfo.start_time}
          duration={groupInfo.duration}
        />
      )}
    </header>
  )
}
