import React from "react"
import "./ModeBox.scss"
import { useT } from "@transifex/react"
import { useSelector } from "react-redux"
import { getModeIndex } from "modules/device/selectors"

const ModeBox = () => {
  const t = useT()
  const modeIndex = useSelector(getModeIndex)

  let modeString
  switch (modeIndex) {
    default:
    case 0:
      modeString = t("Cadence")
      break
    case 1:
      modeString = t("Heart rate")
      break
    case 2:
      modeString = t("Power")
      break
  }

  return (
    <div className="ModeBoxContainer">
      <div className="ModeBox">
        <span>{t("Display mode")}:</span>
        <span className="mode">{modeString}</span>
      </div>
    </div>
  )
}

export default ModeBox
