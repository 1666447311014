import React, { Fragment } from "react"
import NotSupportedPage from "../NotSupportedPage"
import { isChrome, isSafari, isIOS } from "react-device-detect"
import { useT } from "@transifex/react"

const BrowserCompatGate = ({
  browserCompactWarningIgnored,
  ignoreBrowserCompactWarning,
  children,
}) => {
  const t = useT()

  let supported = false
  let message = t("Your browser is not fully supported")
  let suggestion = t(
    "Please use Google Chrome browser to get an optimized experience",
  )
  let link = {
    text: t("Download Google Chrome browser"),
    href: "https://www.google.com/chrome/",
  }

  if (isIOS && isSafari) {
    supported = true
  } else if (isIOS && !isSafari) {
    supported = false
    suggestion = t("Please use Safari browser to get an optimized experience")
    link = undefined
  } else if (isChrome) {
    supported = true
  }

  return (
    <Fragment>
      {children}
      {!supported && !browserCompactWarningIgnored && (
        <NotSupportedPage
          ignoreBrowserCompactWarning={ignoreBrowserCompactWarning}
          message={message}
          suggestion={suggestion}
          link={link}
        />
      )}
    </Fragment>
  )
}

export default BrowserCompatGate
