import { createSelector } from "reselect"
import { State } from "state/store"
import { GameRunningState } from "./gameTypes"
import {
  UnityGameConfig,
  UnityGameData,
  UnityTeamMember,
} from "components/RacingGame3D/UnityContent"

export const getGameRunningState = (state: State) => state.game.running_state
const getGameDuration = (state: State) => state.game.duration
const getGameUnits = (state: State) => state.game.units

const getTeamAUsers = (state: State) => state.game.team_a_users
const getTeamBUsers = (state: State) => state.game.team_b_users
const getTeamAScore = (state: State) => state.game.team_a_score
const getTeamBScore = (state: State) => state.game.team_b_score
const getTeamAScores = (state: State) => state.game.team_a_scores
const getTeamBScores = (state: State) => state.game.team_b_scores
const getTeamASpeed = (state: State) => state.game.team_a_speed_total
const getTeamBSpeed = (state: State) => state.game.team_b_speed_total

export const gameAvailableSelector = createSelector(
  getGameRunningState,
  (runningState) => {
    return runningState !== GameRunningState.None
  },
)

export const gameConfigSelector = createSelector(
  getGameDuration,
  getGameUnits,
  (duration, unit): UnityGameConfig | undefined => {
    if (duration !== undefined && unit !== undefined) {
      return { duration, unit }
    }
  },
)

const getTeamAUserData = createSelector(
  getTeamAUsers,
  getTeamAScores,
  (users, scores): UnityTeamMember[] => {
    return Object.values(users).map((user) => {
      return {
        nickname: user.nickname,
        user_id: user.user_id,
        score: scores[user.session_id],
        team_name: "team_a",
      }
    })
  },
)

const getTeamBUserData = createSelector(
  getTeamBUsers,
  getTeamBScores,
  (users, scores): UnityTeamMember[] => {
    return Object.values(users).map((user) => {
      return {
        nickname: user.nickname,
        user_id: user.user_id,
        score: scores[user.session_id],
        team_name: "team_b",
      }
    })
  },
)

/**
 * This selector returns data in the format that the Unity game expects.
 * See gameTypes.ts for an overview of the Unity data types.
 */
export const gameDataSelector = createSelector(
  getTeamAUserData,
  getTeamBUserData,
  getTeamAScore,
  getTeamBScore,
  getTeamASpeed,
  getTeamBSpeed,
  (
    teamAUserData,
    teamBUserData,
    teamAScore,
    teamBScore,
    teamASpeed,
    teamBSpeed,
  ): UnityGameData => {
    return {
      team1: {
        score: teamAScore,
        speed: teamASpeed,
        users: teamAUserData,
      },
      team2: {
        score: teamBScore,
        speed: teamBSpeed,
        users: teamBUserData,
      },
    }
  },
)
