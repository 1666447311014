import types from "duck/types"
import { combineReducers } from "redux"
import selectors from "duck/selectors"
import { getMotosumoTime, timeUntil } from "tools/time"

const initStats = {}
const statsReducer = (
  state = initStats,
  action = { type: "", payload: {} },
) => {
  switch (action.type) {
    default: {
      return state
    }
  }
}

const initEmojis = {}
const emojiReducer = (
  state = initEmojis,
  action = { type: "", payload: {} },
) => {
  switch (action.type) {
    case types.users.GET_MESSAGE: {
      const { duration, emoji, user_id, expire_timestamp } = action.payload
      return {
        ...state,
        [user_id]: {
          duration,
          emoji,
          expire_timestamp,
        },
      }
    }
    default: {
      return state
    }
  }
}

const MAX_MESSAGE_PER_USER = 5
/**
 * If the user id already exists update the list of emoji.
 * If the user id is new, add a record with a created ts.
 *
 * @param {The existing internal state} state
 * @param {The user emoji message} payload
 */
const updateOrCreateUserEmoji = (state, payload) => {
  const { duration, emoji, user_id, expire_timestamp } = payload
  const ret = {}

  if (state.hasOwnProperty(user_id)) {
    const ue = state[user_id]
    const isExpired = timeUntil(ue.expire_timestamp) <= 0
    if (isExpired) {
      ue.emojis = [emoji]
      // if emoji is expired, but still in the state, we update
      // the create time stamp, since this is a new emoji message.
      ue.created = getMotosumoTime()
    } else {
      ue.emojis = [emoji, ...ue.emojis].slice(0, MAX_MESSAGE_PER_USER)
    }
    ue.expire_timestamp = expire_timestamp
    ret[user_id] = ue
  } else {
    ret[user_id] = {
      user_id,
      duration,
      emojis: [emoji],
      expire_timestamp,
      created: getMotosumoTime(),
    }
  }

  return ret
}

// the last element will be faded out so only MAX_USERS_IN_FEED - 1 will be visible
export const MAX_USERS_IN_FEED = 11

/**
 * Drop user ids from state if the feed grows to more than
 * MAX_USERS_IN_FEED length.
 *
 * @param {An object with user id mappings} state
 */
const dropUserEmojiFromState = (state) => {
  return Object.values(state)
    .filter((ue) => timeUntil(ue.expire_timestamp) > 0)
    .sort(selectors.users.compareUserEmoji)
    .slice(-MAX_USERS_IN_FEED)
    .reduce((newState, ue) => {
      newState[ue.user_id] = ue
      return newState
    }, {})
}

/**
 * A new version of the user feed is proposed as follows:
 * - each user on screen can display multiple emojis
 *   controlled by MAX_MESSAGE_PER_USER.
 * - the on screen feed shows at most MAX_USERS_IN_FEED entries
 *   logic in the getMessages selector.
 * - each entry is created with a timestamp controlling when to
 *   expire the entry from the feed.
 * - an entry expires for two reasons:
 *   > expire_timestamp times out with the latest emoji deciding
 *     thus; keep sending emojis and your entry stays on screen.
 *   > the feed is truncated to be <= MAX_USERS_IN_FEED length
 *     sorted by entry created timestamp.
 */

const initMessages = {}
const messageReducer = (
  state = initMessages,
  action = { type: "", payload: {} },
) => {
  switch (action.type) {
    case types.users.GET_MESSAGE: {
      const updatedUserEmoji = updateOrCreateUserEmoji(state, action.payload)
      const newState = dropUserEmojiFromState({
        ...state,
        ...updatedUserEmoji,
      })

      return newState
    }
    default: {
      return state
    }
  }
}

const usersReducer = combineReducers({
  stats: statsReducer,
  emojis: emojiReducer,
  messages: messageReducer,
})
export default usersReducer
