import React, { useEffect, useState } from "react"
import { bind } from "mousetrap"
import { getIsCasting } from "modules/device/selectors"
import { useSelector } from "react-redux"
import "./Grid.scss"
import { Grid } from "./Grid"
import { IVSScreen } from "components/IVSScreen"

export const DataScreenBody = () => {
  const isCasting = useSelector(getIsCasting)
  const [hasLiveStream, setHasLiveStream] = useState(false)

  bind("command+shift+alt+s", () => {
    setHasLiveStream(!hasLiveStream)
  })

  useEffect(() => {
    setHasLiveStream(!isCasting)
  }, [isCasting])

  return (
    <>
      <Grid />
      {hasLiveStream && <IVSScreen />}
    </>
  )
}
