import instructorSagas from "modules/instructor/duck/sagas"
import timeSaga from "tools/time"
import cronSagas from "modules/cron/duck/sagas"
import gamesSagas from "modules/game/gameSagas"

export default {
  instructor: instructorSagas,
  time: timeSaga,
  cron: cronSagas,
  games: gamesSagas,
}
