import React from "react"
import trophy from "./trophy.png"
class Trophy extends React.Component {
  render() {
    return (
      <div className="reward">
        <img className="reward__trophy" src={trophy} alt="trophy img" />
        <div className="reward__data">
          <span className="reward__data__value">{this.props.calories}</span>
          <span className="reward__data__unit">{"KCAL"}</span>
        </div>
      </div>
    )
  }
}

export default Trophy
