import React, { Component } from "react"
import logo from "./../../assets/images/logo-horizontal.png"
class MotosumoLogo extends Component {
  render() {
    return (
      <div className="LogoContainer">
        <img className="logo" alt="logo" src={logo} />
      </div>
    )
  }
}

export default MotosumoLogo
