import { call, delay } from "redux-saga/effects"
import { median, mean, std } from "./utils"
import api from "../api"

let timeLagList = []
// No initial offset
let currentClientServerTimeDiff = 0
let n = 10

/**
 * Get the current timestamp synchronized with the server.
 * If not synced, falls back to device time.
 */
export function getMotosumoTime() {
  // No initial offset use time as it is
  return Date.now() - currentClientServerTimeDiff
}

export function clientServerTimeInfo() {
  return "Diff: " + currentClientServerTimeDiff + " n: " + n
}

/**
 * Returns the time in miliseconds until the provided time.
 * Can return negative values.
 */
export function timeUntil(time) {
  return time - getMotosumoTime()
}

/**
 * Returns the number of whole seconds until the provided time in miliseconds,
 * using MotosumoTime as the base clock.
 * If the provided time is in the past, 0 seconds is returned.
 * Note: Have to use ceil to get the whole number of seconds left,
 * using round will return 1 second too many half of the time.
 */
export function secondsUntil(time) {
  return Math.max(Math.ceil(timeUntil(time) / 1000), 0)
}

/**
 * Returns the percentage left until the provided time, given a duration,
 * using MotosumoTime as the base clock.
 * If the provided time is in the past, 0 percent is returned.
 */
export function percentUntil(time, duration) {
  return Math.max((timeUntil(time) / 1000 / duration) * 100, 0)
}

export function secondsToTimeString(duration, skipLeadingZeros = false) {
  return millisecondsToTimeString(duration * 1000, skipLeadingZeros)
}

export function millisecondsToTimeString(duration, skipLeadingZeros = false) {
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  let minutesString = ""

  // By default we skip hours if they are zero
  const hoursString = hours === 0 ? "" : hours + ":"

  if (skipLeadingZeros) {
    minutesString = minutes === 0 ? "" : minutes + ":"
  } else {
    minutesString = (minutes < 10 ? "0" + minutes : minutes) + ":"
  }
  const secondsString = seconds < 10 ? "0" + seconds : seconds

  return hoursString + minutesString + secondsString
}
/**
 *
 * @param {number} duration  miliseconds
 * @returns {{}:{ hour ,minute ,second ,milisecond}}
 */
export function millisecondsToTime(duration) {
  const day = 24 * 60 * 60 * 1000
  const hour = 60 * 60 * 1000
  const minute = 60 * 1000
  const second = 1000

  function getHour(duration) {
    let hourString
    if (duration >= hour) {
      hourString = Math.floor(duration / hour) + ""
      hourString = hourString.length > 1 ? hourString : "0" + hourString
    } else {
      hourString = "00"
    }
    return hourString
  }
  function getMinute(duration) {
    let minuteString = ""
    if (duration >= minute) {
      minuteString = Math.floor(duration / minute) + ""
      minuteString = minuteString.length > 1 ? minuteString : "0" + minuteString
    } else {
      minuteString = "00"
    }
    return minuteString
  }

  function getSecond(duration) {
    let secondString = ""
    if (duration >= second) {
      secondString = Math.ceil(duration / second) + ""
      secondString = secondString.length > 1 ? secondString : "0" + secondString
    } else {
      secondString = "00"
    }
    return secondString
  }

  if (duration > day) {
    throw new Error("MAX TIME REACHED")
  } else if (duration >= hour) {
    let hourString = getHour(duration)
    let remaining = duration - parseInt(hourString) * hour
    let minuteString = getMinute(remaining)

    remaining = remaining - parseInt(minuteString) * minute
    let secondString = getSecond(remaining)
    return {
      hour: hourString,
      minute: minuteString,
      second: secondString,
    }
  } else if (duration >= minute) {
    let minuteString = getMinute(duration)
    let remaining = duration - parseInt(minuteString) * minute
    let secondString = getSecond(remaining)
    return {
      hour: "00",
      minute: minuteString,
      second: secondString,
    }
  } else if (duration >= second) {
    let secondString = getSecond(duration)
    return {
      hour: "00",
      minute: "00",
      second: secondString,
    }
  } else {
    return {
      hour: "00",
      minute: "00",
      second: "00",
    }
  }
}

// // http://www.mine-control.com/zack/timesync/timesync.html

function meanFilteredTimelag(lst) {
  // The client repeats steps 1 through 3 five or more times, pausing a few seconds each time.
  // Other traffic may be allowed in the interim, but should be minimized for best results
  // All samples above approximately 1 standard-deviation from the median are discarded
  const filteredList = lst.filter((f) => f < median(lst) + std(lst))

  // the remaining samples are averaged using an arithmetic mean, rounding to whole miliseconds
  return Math.round(mean(filteredList))
}

function calculateClockDelta(msg) {
  // Upon receipt by client, client subtracts current time from sent time and divides by two to compute latency.
  // It subtracts server time from current time to determine client-server time delta and subtracts the half-latency to get the correct clock delta.
  // (So far this algothim is very similar to SNTP)
  const currentTime = Date.now()
  // Latency is half the round trip time to the server
  const latency = (currentTime - msg.sent_time) / 2

  //
  // NOTE: It is important to get the sign correct :) otherwise you will get a
  //       noticable difference in the opposite direction. In effect making your
  //       clock difference twice as large.
  //
  const clientServerTimeDelta = currentTime - msg.timestamp_server // msg (recieve - sent) time
  const clockDelta = clientServerTimeDelta - latency // Subtract latency

  return clockDelta
}

// http://www.mine-control.com/zack/timesync/timesync.html

/**
 *
 * @param {number} iterations number of times to call restTimeSync function
 *
 * calculate the time difference between client and server,
 * by calling restTimeSync a number of times specified by
 * parameter 'iterations'.
 *
 * if there is no error while calling restTimeSync function,
 * calculate latency and save the latency into timeLagList
 * array, then calculate the mean of saved latency in timeLagList
 * array.
 *
 * if there is an error while calling the restTimeSync function,
 * wait 1 second, increase the number of iterations by 1 ,because
 * timeLagList is not updated in this call.
 */
function* startRestTimeSync(iterations = 10) {
  n = iterations
  timeLagList = []

  while (n > 0) {
    // send time sync requests and record latencies
    const response = yield call(api.restTimeSync)
    if (response && response.status === 200 && response.data) {
      // We prepend to the list to be able to only keep a max list length
      const msg = response.data
      timeLagList.unshift(calculateClockDelta(msg))
    } else {
      // if the response is not valid
      yield delay(1000)
      n++
    }

    // Wait for awhile
    for (let i = 0; i < 4; i++) {
      // 500 +/- 100 ms random delay to avoid (theoretical) timer collisions
      // from 1600 to 2400 millis
      yield delay(500 + (Math.random() - 0.5) * 200)
    }

    // Update a running estimate of the clock delta
    if (timeLagList && timeLagList.length > 2) {
      currentClientServerTimeDiff = meanFilteredTimelag(timeLagList)
    }

    n--
  }

  currentClientServerTimeDiff = meanFilteredTimelag(timeLagList)
}

export default {
  startRestTimeSync,
}
