import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import canAutoPlay from "can-autoplay"
import {
  currentStreamSelector,
  streamLocalizedWorkoutLanguage,
} from "modules/stream/streamSelectors"
import { getFlags } from "flags/flagsSelectors"
import "./IVSScreen.scss"
import { useT } from "@transifex/react"

/**
 * If autoplay is muted or blocked, the viewer will need to manually interact with the
 * video player in order to unmute or start playback.
 * See https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
 * for more info on autoplaying video and best practices.
 * */
export const IVSScreen = () => {
  const flags = useSelector(getFlags)
  const t = useT()
  const currentStream = useSelector(currentStreamSelector)
  const localizedWorkoutLanguage = useSelector(streamLocalizedWorkoutLanguage)

  const [autoplayAllowed, setAutoplayAllowed] = useState(false)

  const isLive = currentStream?.operator === "star"

  const checkAutoPlay = async () => {
    const { result } = await canAutoPlay.video({ timeout: 500, muted: false })
    if (result === true) {
      setAutoplayAllowed(true)
    }
  }

  const toggleAutoplayAllowed = () => {
    setAutoplayAllowed(!autoplayAllowed)
  }

  useEffect(() => {
    checkAutoPlay()
  }, [])

  return (
    <div>
      <div className="IVSScreen">
        <div className="VideoContainer">
          <a className="MuteButton" onClick={toggleAutoplayAllowed}>
            {autoplayAllowed ? "🔈" : "🔇"}
          </a>
          <video
            className="VideoContent"
            muted={!autoplayAllowed}
            autoPlay={false}
            disablePictureInPicture
          />
          <div className="VideoOverlay" />
          {isLive && <div className="LiveLabelVideo">{t("LIVE")}</div>}

          <div className="VideoLabelContainer">
            <div>{localizedWorkoutLanguage}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
