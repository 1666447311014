import React from "react"
import "themes/index.scss"

import { useSelector } from "react-redux"
import { RacingGame3D } from "components/RacingGame3D/RacingGame3D"
import { gameAvailableSelector } from "modules/game/gameSelectors"
import { getIsCasting } from "modules/device/selectors"
import WaitingForClassScreen from "components/WaitingForClassScreen/WaitingForClassScreen"
import { DataScreen } from "components/DataScreen/DataScreen"
import { BottomContainer } from "components/BottomContainer/BottomContainer"
import { MotifPickerModal } from "components/MotifPicker/MotifPickerModal"

export const App = () => {
  const isCasting = useSelector(getIsCasting)
  const gameAvailable = useSelector(gameAvailableSelector)

  // To avoid having to reload the Unity game content for each new game,
  // we keep the RacingGame3D component mounted, and control its visibility
  // using CSS, but only when casting, as running the game in a live class
  // requires adjustment for latency, which is not currently implemented.
  const mountRacingGame = isCasting
  const mountDataScreen = !(mountRacingGame && gameAvailable)

  return (
    <div className="App">
      {mountDataScreen && <DataScreen />}
      <WaitingForClassScreen />
      {mountRacingGame && <RacingGame3D />}
      {mountDataScreen && <BottomContainer />}
      <MotifPickerModal />
    </div>
  )
}
