import { mapValues } from "lodash"
import flagsmith from "flagsmith"

// The definitions for all the Flagsmith feature flags the teamdisplay supports.
export const flagDefinitions = {
  example_flag: {},
}

// This is the type of:
// - The initial flag values (defined below)
// - The updates to the flag values (see createFlagsChannel() in flagsSaga.ts)
// - The flags redux state (see flagsReducer.ts)
export type Flags = {
  [key in keyof typeof flagDefinitions]: {
    enabled: boolean
    value: string | number | boolean | null
  }
}

export const initialFlags: Flags = mapValues(
  flagDefinitions,
  (_, flagName) => ({
    enabled: flagsmith.hasFeature(flagName),
    value: flagsmith.getValue(flagName),
  }),
)
