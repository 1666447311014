import { Channel } from "phoenix"
import actions from "duck/actions"
import {
  connectToChannel,
  waitForMessages,
  waitForSocketConnection,
} from "socket"
import { getChannelId, getDeviceToken, getIsCasting } from "./selectors"

import {
  castGroupAction,
  classStatsAction,
  classStoppedAction,
  clearClassStatsAction,
  clearGroupAction,
  deviceEndAction,
  joinGroupAction,
  leaveGroupAction,
  modeUpdateAction,
  newDeviceTokenAction,
  startCastingAction,
} from "./deviceSlice"
import { gameAvailableAction } from "modules/game/gameSlice"
import { call, fork, put, select, take, takeLatest } from "typed-redux-saga"
import {
  currentStreamChangedAction,
  currentStreamStartedAction,
} from "modules/stream/streamSlice"

/**
 * Default startup, the device connects to the SW to
 * get the next valid token to diplay for clients to connect
 * to this specific device. Tokens are unique and valid
 * per one device only.
 */
export function* deviceChannelSaga() {
  let channel: Channel | undefined
  try {
    channel = yield* call(
      connectToChannel,
      yield* call(waitForSocketConnection),
      "device",
    )

    if (channel === undefined) {
      return
    }

    yield* fork(waitForMessages, channel, "device_token", newDeviceTokenAction)

    yield* take(deviceEndAction)
  } finally {
    channel?.leave()
  }
}

/**
 * This is the specific token channel we connect to once
 * initial startup is done. The next expected action here
 * is a cast when a client connects.
 */
function* deviceTokenChannelSaga(
  cast: ReturnType<typeof newDeviceTokenAction>,
) {
  let channel: Channel | undefined
  try {
    channel = yield* call(
      connectToChannel,
      yield* call(waitForSocketConnection),
      "device:" + cast.payload.device_token,
      {},
    )

    if (channel === undefined) {
      return
    }

    yield* fork(waitForMessages, channel, "mode_update", modeUpdateAction)

    yield* fork(
      waitForMessages,
      channel,
      "cast",
      castGroupAction,
      clearGroupAction,
    )

    yield* fork(waitForMessages, channel, "stop_casting", classStoppedAction)

    yield* take(deviceEndAction)
  } catch (error) {
    console.error(error)
  } finally {
    channel?.leave()
  }
}

export function* watchDeviceTokenChannelSaga() {
  yield* takeLatest([newDeviceTokenAction], deviceTokenChannelSaga)
}

/**
 * Saga that handles joining and leaving stream group channels.
 *
 * a. If casting, wait until we are no longer casting (classStoppedAction). Then proceed
 * to check if the current stream changed or started.
 *
 * b1. If the current stream changed we leave the current group channel to avoid
 * receiving messages on the waiting screen.
 * b2. If the current stream starts (or has already started) we join the group stream
 * channel for that stream to start receiving messages.
 */
export function* monitorCurrentStreamSaga() {
  yield* takeLatest(
    [currentStreamChangedAction, currentStreamStartedAction],
    function* (action) {
      if (yield* select(getIsCasting)) {
        yield* take(classStoppedAction)
      }
      if (action.type === currentStreamChangedAction.type) {
        yield* put(leaveGroupAction())
      }
      if (action.type === currentStreamStartedAction.type) {
        yield* put(joinGroupAction())
      }
    },
  )
}

/**
 * A group/class is connected on either a user casting a group or an
 * IVS stream with group.
 */
export function* joinGroupChannelSaga() {
  yield* takeLatest([joinGroupAction, startCastingAction], function* () {
    const channelId = yield* select(getChannelId)
    let channel: Channel = yield* call(
      connectToChannel,
      yield* call(waitForSocketConnection),
      "class:" + channelId ?? "",
      {
        updateRate: 2000,
      },
    )
    try {
      yield* fork(waitForMessages, channel, "stats", classStatsAction)
      yield* fork(
        waitForMessages,
        channel,
        "user_says",
        actions.users.getMessage,
      )
      yield* fork(
        waitForMessages,
        channel,
        "instructor_says",
        actions.instructor.getMessage,
      )
      yield* fork(
        waitForMessages,
        channel,
        "game_available",
        gameAvailableAction,
      )
      yield* fork(waitForMessages, channel, "stopped", classStoppedAction)
      yield* take([leaveGroupAction, classStoppedAction])
    } finally {
      channel?.leave()
      yield* put(clearClassStatsAction())
    }
  })
}

export function* monitorGroupCastMessagesSaga() {
  yield* takeLatest(castGroupAction, function* (action) {
    const deviceToken = yield* select(getDeviceToken)
    if (action.payload.device_token === deviceToken) {
      yield* put(startCastingAction(action.payload))
    }
  })
}
