import {
  call,
  put,
  delay,
  race,
  take,
  takeLatest,
  select,
} from "typed-redux-saga"
import api from "api"
import {
  newStreamsAction,
  fetchStreamsAction,
  currentStreamChangedAction,
  currentStreamStartedAction,
} from "./streamSlice"
import { Stream } from "models/Stream"
import { selectMotifAction } from "modules/motif/motifSlice"
import { isEqual } from "lodash"
import {
  currentStreamSelector,
  streamStartTimeSelector,
} from "modules/stream/streamSelectors"
import moment from "moment"

const STREAM_FETCH_INTERVAL = 30000
const STREAM_FETCH_RETRY_DELAY = 10000

/**
 * Checks periodically for new streams or on reloadStreamsAction.
 */
export function* fetchStreamsSaga() {
  while (true) {
    try {
      const streams = yield* call(api.getStreams)
      yield* put(newStreamsAction(streams))
    } catch (error) {
      console.error(
        `${error}, retrying in ${STREAM_FETCH_RETRY_DELAY / 1000}s..`,
      )
      yield* delay(STREAM_FETCH_RETRY_DELAY)
      continue
    }
    yield* race([take(fetchStreamsAction), delay(STREAM_FETCH_INTERVAL)])
  }
}

/**
 * Monitors the current stream and the selected motif. If either changes,
 * and the result is a new currentStream, triggers a currentStreamChangedAction.
 */
export function* monitorStreamsSaga() {
  let previousStream: Stream | undefined
  yield* takeLatest([newStreamsAction, selectMotifAction], function* () {
    const currentStream = yield* select(currentStreamSelector)
    if (!isEqual(previousStream, currentStream)) {
      previousStream = currentStream
      yield* put(currentStreamChangedAction())
    }
  })
}

/**
 * Triggers a currentStreamStartedAction within 1 second of the
 * current stream's start time.
 */
export function* monitorCurrentStreamStartSaga() {
  yield* takeLatest(currentStreamChangedAction, function* () {
    const streamStartTime = yield* select(streamStartTimeSelector)
    while (moment().isBefore(streamStartTime)) {
      yield* delay(1000)
    }
    yield* put(currentStreamStartedAction())
  })
}
