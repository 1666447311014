import { fork, delay, cancel } from "redux-saga/effects"
import { t } from "@transifex/native"

export function getLocalizedWorkoutLanguage(workoutLocale: string) {
  switch (workoutLocale) {
    case "en_US":
      return t("English")
    case "es_419":
      return t("Spanish")
    case "pt_BR":
      return t("Portuguese")
    default:
      return t("Other")
  }
}

export function vwTOpx(value) {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth
  // y = w.innerHeight || e.clientHeight || g.clientHeight

  var result = (x * value) / 100
  return result
}

export function mean(buffer) {
  if (buffer.length > 0) {
    return Math.round(buffer.reduce((a, b) => a + b) / buffer.length)
  }
  return 0
}

export function median(buffer) {
  if (buffer.length > 0) {
    buffer.sort((a, b) => a - b)
    return (buffer[(buffer.length - 1) >> 1] + buffer[buffer.length >> 1]) / 2
  }
  return 0
}

export function std(buffer) {
  if (buffer.length > 0) {
    const bufferMean = mean(buffer)
    return Math.sqrt(
      buffer
        .map((x) => Math.pow(x - bufferMean, 2)) // power
        .reduce((a, b) => a + b) / // sum
        (buffer.length - 1), // normalization
    )
  }
  return 0
}

export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}
export function createLogger(name) {
  return function log(msg, method, obj) {
    if (method) {
      console.log(
        name + "::" + method + ", " + msg,
        obj !== undefined ? obj : "",
      )
    } else {
      console.log(name + ", " + msg, obj !== undefined ? obj : "")
    }
  }
}

export function* recurringTask(task, runWithIntervalInMins) {
  while (true) {
    const ref = yield fork(task)

    yield delay(60 * 1000 * runWithIntervalInMins)
    yield cancel(ref)
  }
}

// Convert unicode to characters. Used for rendering emojis.
export const unicodeToChar = (text) => {
  return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16))
  })
}
