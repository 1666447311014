import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ReducerWithInitialState } from "@reduxjs/toolkit/dist/createReducer"
import {
  GameAvailable,
  GameRunningState,
  GameScoreMap,
  GameSessionMap,
  GameStart,
  GameType,
  GameUnits,
  GameUpdate,
  GameUser,
  GameChannelPresenceDiff,
  GameChannelPresenceState,
  GameChannelUser,
} from "./gameTypes"

export interface GameState {
  duration?: number
  game_id?: string
  type: GameType
  units: GameUnits
  running_state: GameRunningState
  game_stop_ts: number
  // Total team scores
  team_a_score: number
  team_b_score: number
  // Individual team member scores
  team_a_scores: GameScoreMap
  team_b_scores: GameScoreMap
  // More info about each team member
  team_a_users: GameSessionMap
  team_b_users: GameSessionMap
  team_a_speed_total: number
  team_b_speed_total: number
}

const initialState: GameState = {
  duration: undefined,
  game_id: undefined,
  type: "racing_legends",
  units: "meters",
  running_state: GameRunningState.None,
  game_stop_ts: 0,
  team_a_score: 0,
  team_b_score: 0,
  team_a_scores: {},
  team_b_scores: {},
  team_a_users: {},
  team_b_users: {},
  team_a_speed_total: 0,
  team_b_speed_total: 0,
}

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    gameAvailableAction(state, action: PayloadAction<GameAvailable>) {
      state.game_id = action.payload.game_id
      state.duration = action.payload.duration
      state.type = action.payload.type
      state.units = action.payload.units
      state.running_state = GameRunningState.Available
    },
    gameStartedAction(state, action: PayloadAction<GameStart>) {
      state.duration = action.payload.duration
      state.game_stop_ts = action.payload.game_stop_ts
      state.running_state = GameRunningState.Running
    },
    gameUpdatedAction(state, action: PayloadAction<GameUpdate>) {
      state.team_a_score = action.payload.team_a_score
      state.team_b_score = action.payload.team_b_score
      state.team_a_scores = action.payload.team_a_scores
      state.team_b_scores = action.payload.team_b_scores
      state.team_a_speed_total = action.payload.team_a_speed_total
      state.team_b_speed_total = action.payload.team_b_speed_total
    },
    gameFinishedAction(state) {
      state.running_state = GameRunningState.Finished
    },
    gameEndedAction(state) {
      return initialState
    },
    gamePresenceStateAction(
      state,
      action: PayloadAction<GameChannelPresenceState>,
    ) {
      state.team_a_users = {
        ...state.team_a_users,
        ...extractTeamMembers(action.payload, "team_a"),
      }
      state.team_b_users = {
        ...state.team_b_users,
        ...extractTeamMembers(action.payload, "team_b"),
      }
    },
    gamePresenceDiffAction(
      state,
      action: PayloadAction<GameChannelPresenceDiff>,
    ) {
      state.team_a_users = {
        ...state.team_a_users,
        ...extractTeamMembers(action.payload.joins, "team_a"),
      }
      state.team_b_users = {
        ...state.team_b_users,
        ...extractTeamMembers(action.payload.joins, "team_b"),
      }
    },
  },
})

const extractTeamMembers = (
  users: GameChannelPresenceState,
  teamName: string,
): GameSessionMap => {
  const usr: GameChannelUser[] = Object.values(users)
  const res: GameSessionMap = {}
  for (let i = 0; i < usr.length; i++) {
    const gcu: GameChannelUser = usr[i]
    if (gcu.metas[0].team_name === teamName) {
      const u: GameUser = {
        user_id: gcu.id,
        session_id: gcu.metas[0].session_id,
        nickname: gcu.nickname,
        team_name: gcu.metas[0].team_name,
      }
      res[gcu.metas[0].session_id] = u
    }
  }
  return res
}

export const {
  gameAvailableAction,
  gameStartedAction,
  gameUpdatedAction,
  gameFinishedAction,
  gameEndedAction,
  gamePresenceStateAction,
  gamePresenceDiffAction,
} = gameSlice.actions

export const gameReducer =
  gameSlice.reducer as ReducerWithInitialState<GameState>

gameReducer.getInitialState = () => initialState
