import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ReducerWithInitialState } from "@reduxjs/toolkit/dist/createReducer"
import { PlayerState, TextMetadataCue } from "amazon-ivs-player"

export interface IvsState {
  playerState?: PlayerState
  metadataCue?: TextMetadataCue
  videoProgress: number
}

const initialState: IvsState = {
  videoProgress: 0,
}

const ivsSlice = createSlice({
  name: "ivs",
  initialState,
  reducers: {
    newPlayerStateAction(state, action: PayloadAction<PlayerState>) {
      state.playerState = action.payload
    },
    newMetadataCueAction(state, action: PayloadAction<TextMetadataCue>) {
      state.metadataCue = action.payload
    },
    videoProgressAction(state, action: PayloadAction<number>) {
      state.videoProgress = action.payload
    },
    reloadVideoAction(state) {
      state.playerState = undefined
    },
    ivsSagaLoadedAction() {},
  },
})

export const {
  newPlayerStateAction,
  newMetadataCueAction,
  videoProgressAction,
  reloadVideoAction,
  ivsSagaLoadedAction,
} = ivsSlice.actions

export const ivsReducer = ivsSlice.reducer as ReducerWithInitialState<IvsState>

ivsReducer.getInitialState = () => initialState
