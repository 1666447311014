import React from "react"
import { useT } from "@transifex/react"

const GroupCode = ({ groupCode, groupName }) => {
  const t = useT()

  return (
    <div className="GroupCode">
      <div className="value">{groupCode}</div>
      <div className="TitleContainer">
        {
          // E.g. "Lottie's team" / "Equipo de Lottie"
          t("{nickname} {team_possessive}", {
            _context: "Example: Lottie's team",
            nickname: <div className="title groupName">{groupName}</div>,
            team_possessive: <div className="title">{t("'s team")}</div>,
          })
        }
      </div>
    </div>
  )
}
export default GroupCode
