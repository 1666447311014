import React from "react"
import Trophy from "./Trophy"
import { shouldRewardByCalories } from "../../tools/reward"
import { calorieRewardValues } from "../../tools/reward/calorieRewardValues"
import UserEmojiMsg from "./UserEmojiMsg"
import { getMotosumoTime } from "tools/time"
import Nickname from "components/Nickname"
import { cadenceToZoneEffort } from "tools/zones"
import ProfilePicture from "components/ProfilePicture/ProfilePicture"

class Cell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reward: false,
      rewardPoint: null,
    }
  }

  updateRewardState = (prevProps, prevState) => {
    let shouldReward = shouldRewardByCalories(
      prevProps.calories,
      this.props.calories,
      calorieRewardValues,
    )
    if (
      shouldReward.result &&
      !prevState.reward.result &&
      this.props.is_active
    ) {
      this.setState({
        ...this.state,
        reward: true,
        rewardPoint: shouldReward.rewardPoint,
      })
      setTimeout(() => {
        this.setState({ ...this.state, reward: false })
      }, 3000)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateRewardState(prevProps, prevState)
  }

  render() {
    const passiveColor = "#3f4749"
    const now = getMotosumoTime()
    return (
      <div className={`Cell ${this.props.is_active ? "" : "Cell--passive"}`}>
        <div
          className={`Cell__value-container ${cadenceToZoneEffort(
            this.props.value,
          )}`}
          style={{
            transition: `background-color .5s`,
            backgroundColor: `${
              this.props.is_active ? this.props.zone : passiveColor
            }`,
          }}
        >
          <div
            className={"Cell__value-container--front"}
            style={{
              transform: `rotateX(${this.state.reward ? 180 : 0}deg)`,
            }}
          >
            <div className="modeValue">
              <span className="modeValue--number">
                {Math.round(parseFloat(this.props.value))}
              </span>
              <span className="modeValue--unit">{this.props.unit}</span>
            </div>
          </div>
          <div
            className={"calorie Cell__value-container--back"}
            style={{
              transform: `rotateX(${this.state.reward ? 0 : 180}deg)`,
            }}
          >
            <Trophy calories={this.state.rewardPoint} />
          </div>
        </div>
        <div
          className="Cell__nicknameContainer"
          style={{
            transition: `background-color .5s`,
            backgroundColor: `${
              this.props.is_active ? this.props.zone : passiveColor
            }`,
          }}
        >
          {this.props.currentUserNumber < 17 && (
            <div className="Cell__profilePicture">
              <ProfilePicture userId={this.props.user_id} />
            </div>
          )}
          <Nickname
            nickname={this.props.nickname}
            nicknameClass={"Cell__nicknameContainer"}
            numberOfUsers={this.props.currentUserNumber}
          />
        </div>
        {this.props.userEmoji &&
          this.props.userEmoji.expire_timestamp > now && (
            <UserEmojiMsg
              emojiMsg={this.props.userEmoji}
              key={this.props.userEmoji.expire_timestamp}
            />
          )}
      </div>
    )
  }
}

export default Cell
