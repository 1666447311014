import React from "react"
import PeopleIcon from "./PeopleIcon"
import team from "assets/images/team.png"
class MemberBox extends React.Component {
  render() {
    return (
      <div className="MemberBox">
        <img className="team" src={team} />
        <div>{this.props.currentUserNumber}</div>
      </div>
    )
  }
}
export default MemberBox
