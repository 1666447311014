import {
  call,
  put,
  delay,
  select,
  take,
  takeLatest,
  race,
  fork,
} from "typed-redux-saga"
import {
  createIvsPlayer,
  deleteIvsPlayer,
  getIvsVideoElement,
} from "./ivsPlayer"
import {
  isReplaySelector,
  replayEndTimestampSelector,
  replayStartTimestampSelector,
  streamEndTimeSelector,
  streamPlaybackUrlSelector,
  streamStartTimeSelector,
} from "modules/stream/streamSelectors"
import {
  ivsSagaLoadedAction,
  newPlayerStateAction,
  reloadVideoAction,
} from "./ivsSlice"
import {
  fetchStreamsAction,
  currentStreamChangedAction,
} from "modules/stream/streamSlice"
import {
  MediaPlayer,
  PlayerEventType,
  PlayerState,
  TextMetadataCue,
} from "amazon-ivs-player"
import { EventChannel, eventChannel } from "redux-saga"
import { calculateVideoProgress } from "modules/stream/streamUtils"
import {
  classStoppedAction,
  startCastingAction,
} from "modules/device/deviceSlice"
import { getIsCasting } from "modules/device/selectors"
import { deserializeInstructorIvsMetadata } from "hooks/ivsMetadata"
import { getMotosumoTime } from "tools/time"
import actions from "duck/actions"
import { EmojiMessage } from "models/Emoji"
import { CountdownTimerMessage } from "models/Timer"
import moment from "moment"

// Timeout before video reloads when playback stops or video fails to load.
const VIDEO_PLAYBACK_TIMEOUT = 5 * 1000

// Timeout before video reloads when loading fails (i.e. the video is 404).
const VIDEO_RELOAD_TIMEOUT = 10 * 1000

export function* monitorPlayerStateSaga(ivsPlayer: MediaPlayer) {
  const playerStateChannel: EventChannel<PlayerState> = eventChannel((emit) => {
    for (const state in PlayerState) {
      ivsPlayer.addEventListener(PlayerState[state], () => {
        emit(PlayerState[state])
      })
    }
    return () => {}
  })
  while (true) {
    const playerState = yield* take(playerStateChannel)
    yield* put(newPlayerStateAction(playerState))
  }
}

function* handleMetadataCue(cue: TextMetadataCue) {
  if (cue?.text === undefined) {
    return
  }
  const metadata = deserializeInstructorIvsMetadata(cue.text)
  // The message is in fact an InstructorMessageOut as it's serialized directly
  // from a InstructorMessageOut payload.
  if (metadata?.type === "timer" && metadata?.payload !== undefined) {
    let msg: CountdownTimerMessage | EmojiMessage | undefined
    if (metadata.payload.countdown !== undefined) {
      // Countdown message
      msg = {
        countdown: metadata.payload.countdown,
        color: metadata.payload.color || "green",
        expire_timestamp: getMotosumoTime() + metadata.payload.countdown * 1000,
      } as CountdownTimerMessage
      yield* put(actions.instructor.countDownClass(msg))
    } else if (metadata.payload.duration !== undefined) {
      // Emoji message
      msg = {
        duration: metadata.payload.duration,
        emoji: metadata.payload.emoji,
        expire_timestamp: getMotosumoTime() + metadata.payload.duration,
      } as EmojiMessage
      yield* put(actions.instructor.playEmoji(msg))
    }
  }
}

export function* monitorMetadataCuesSaga(ivsPlayer: MediaPlayer) {
  const metadataCueChannel: EventChannel<TextMetadataCue> = eventChannel(
    (emit) => {
      ivsPlayer.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue) => {
        emit(cue)
      })
      return () => {
        ivsPlayer.removeEventListener(
          PlayerEventType.TEXT_METADATA_CUE,
          (cue) => {
            emit(cue)
          },
        )
      }
    },
  )
  while (true) {
    const metadataCue = yield* take(metadataCueChannel)
    yield* fork(handleMetadataCue, metadataCue)
  }
}

function* attachVideoElement(ivsPlayer: MediaPlayer) {
  while (true) {
    const videoElement = getIvsVideoElement()
    if (videoElement !== null) {
      ivsPlayer.attachHTMLVideoElement(videoElement)
      return
    }
    yield* delay(1000)
  }
}

/**
 * Monitor IVS player events. If we don't receive TIME_UPDATE events within
 * VIDEO_PLAYBACK_TIMEOUT, then reload the stream.
 * Note that timing out is the standard behavior when a stream ends.
 */
function* monitorPlaybackSaga(ivsPlayer: MediaPlayer) {
  const playerTimeUpdateEventChannel: EventChannel<boolean> = eventChannel(
    (emit) => {
      ivsPlayer.addEventListener(PlayerEventType.TIME_UPDATE, () => {
        emit(true)
      })
      return () => {
        ivsPlayer.removeEventListener(PlayerEventType.TIME_UPDATE, () => {
          emit(true)
        })
      }
    },
  )
  while (true) {
    const { timeout } = yield* race({
      playerTimeUpdateEvent: take(playerTimeUpdateEventChannel),
      timeout: delay(VIDEO_PLAYBACK_TIMEOUT),
    })
    if (timeout) {
      console.log("playbackSaga: video playback failed, reloading..")
      // We can end up here either by an actual error, or simply because the stream
      // ended. If the stream ended, we should load new streams from the management.
      // Any update to the streams will trigger a video reload via
      // monitorStreamsSaga, but in the case that the video playback stopped due
      // to an error we also trigger a video reload directly using reloadVideoAction.
      yield* put(fetchStreamsAction())
      yield* put(reloadVideoAction())
    }
  }
}

function* replaySaga(ivsPlayer: MediaPlayer) {
  const streamStartTime = yield* select(streamStartTimeSelector)
  const replayStartTimestamp = yield* select(replayStartTimestampSelector)
  const streamEndTimestamp = yield* select(replayEndTimestampSelector)

  if (
    streamStartTime === undefined ||
    replayStartTimestamp === undefined ||
    streamEndTimestamp === undefined
  ) {
    throw new Error("replay stream start and/or end time not defined")
  }

  const videoProgress = calculateVideoProgress(
    streamStartTime,
    replayStartTimestamp,
  )

  // If the stream has already ended, load streams and return
  if (videoProgress >= streamEndTimestamp) {
    yield* put(fetchStreamsAction())
    return
  }

  // If the stream is ongoing:
  //     1. Seek to video progress
  //     2. Monitor playback events
  //     4. Start playback
  //     5. Enter loop to pause video when stream ends.
  ivsPlayer.seekTo(videoProgress)
  yield* fork(monitorPlaybackSaga, ivsPlayer)
  ivsPlayer.play()

  // Pause video when recording ends
  while (true) {
    const videoProgress = calculateVideoProgress(
      streamStartTime,
      replayStartTimestamp,
    )
    if (videoProgress >= streamEndTimestamp) {
      ivsPlayer.pause()
      return
    }
    yield* delay(1000)
  }
}

function* waitForStreamStartSaga() {
  const streamStartTime = yield* select(streamStartTimeSelector)
  while (moment().isBefore(streamStartTime)) {
    yield* delay(1000)
  }
}

function* waitForStreamEndSaga() {
  const streamEndTime = yield* select(streamEndTimeSelector)
  while (moment().isBefore(streamEndTime)) {
    yield* delay(1000)
  }
}

/**
 * Waits for the IVS player to become READY, then waits for stream to start, the
 * forks replaySaga, or starts live stream, then waits for stream to end and finally
 * pauses the video and reloads streams.
 */
function* playbackSaga(ivsPlayer: MediaPlayer) {
  while (true) {
    const action = yield* take(newPlayerStateAction)
    if (action.payload === PlayerState.READY) {
      yield* call(waitForStreamStartSaga)
      const isReplay = yield* select(isReplaySelector)
      if (isReplay) {
        yield* fork(replaySaga, ivsPlayer)
      } else {
        yield* fork(monitorPlaybackSaga, ivsPlayer)
        ivsPlayer.play()
      }
      yield* call(waitForStreamEndSaga)
      ivsPlayer.pause()
      yield* put(fetchStreamsAction())
    }
  }
}

/**
 * Load the specified stream and prepares the player for playback.
 * On success, the player state changes to PlayerState.READY.
 * On failure, this invokes the PlayerEventType.ERROR listener.
 * If loading the video fails, as is the standard behaviour for live streams before
 * they go live, the saga will retry loading after a VIDEO_RELOAD_TIMEOUT delay.
 */
function* loadVideoSaga(ivsPlayer: MediaPlayer, playbackUrl: string) {
  while (true) {
    ivsPlayer.load(playbackUrl)
    const { playerState } = yield* race({
      playerState: take(newPlayerStateAction),
      timeout: delay(VIDEO_RELOAD_TIMEOUT),
    })
    if (playerState?.payload === PlayerState.READY) {
      return
    } else if (playerState) {
      yield* delay(VIDEO_RELOAD_TIMEOUT)
    }
  }
}

/**
 * Re-create ivs MediaPlayer, Attach video element, load video and fork playbackSaga
 * when the reloadVideoAction is dispatched, or if casting is stopped
 * (via classStoppedAction). If casting is started, takeLatest will cancel all forks
 * and return to avoid starting video playback in the background while casting.
 *
 * To ensure we clean up and don't leak listeners on the IVS MediaPlayer instance, we
 * recreate the MediaPlayer using the delete() method each time a new ivsSaga is forked.
 */
export function* ivsSaga() {
  yield* takeLatest(
    [
      reloadVideoAction,
      classStoppedAction,
      startCastingAction,
      currentStreamChangedAction,
    ],
    function* (action) {
      deleteIvsPlayer()
      if (yield* select(getIsCasting)) {
        return
      }
      const playbackUrl = yield* select(streamPlaybackUrlSelector)
      if (playbackUrl !== undefined) {
        const ivsPlayer = createIvsPlayer()
        yield* fork(monitorPlayerStateSaga, ivsPlayer)
        yield* fork(monitorMetadataCuesSaga, ivsPlayer)
        yield* call(attachVideoElement, ivsPlayer)
        yield* fork(playbackSaga, ivsPlayer)
        yield* fork(loadVideoSaga, ivsPlayer, playbackUrl)
        yield* put(ivsSagaLoadedAction())
      }
    },
  )
}
