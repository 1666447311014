import React, { Component } from "react"
import { useT } from "@transifex/react"

const TeamCalorieBox = ({ teamCalorie }) => {
  const t = useT()

  return (
    <div className="TeamCalories">
      <div className="value">
        {Math.round(teamCalorie ?? 0)}
        <span className="unit"> {t("kcal")}</span>
      </div>
      <div className="title">{t("Team calories")}</div>
    </div>
  )
}
export default TeamCalorieBox
