import { Socket, Channel } from "phoenix"
import { useEffect, useState } from "react"

import { Action } from "redux"
import { END, eventChannel, EventChannel } from "redux-saga"
import { call, cancelled, delay, put, take } from "redux-saga/effects"

/**
 * One global socket for a device to connect with the statsworker on.
 */
const socketUrl = process.env.REACT_APP_STATSWORKER_URL + "/device"

export const socket = new Socket(socketUrl, {
  reconnectAfterMs: function (tries) {
    return [1000, 2000, 5000, 10000][tries - 1] || 10000
  },
  params: {},
})
socket.connect()

const useTopic = <T>(
  channel: Channel | undefined,
  topic: string,
): T | undefined => {
  const [msg, setMsg] = useState<T>()

  useEffect(() => {
    const ref = channel?.on(topic, setMsg)
    return () => {
      channel?.off(topic, ref)
    }
  }, [channel])

  return msg
}

/**
 * Initially all communication starts on the device channel.
 * A device joins the device channel, gets a token as a response to
 * display. Anyone with this token can 'cast' to this particular
 * device using this token. If this happens a 'private_group' message
 * appears to tell the display which group id to show stats from.
 * No logic except, on any 'private_group' message show stats from
 * this group.
 * A reload of the display makes the display reconnect and show a new
 * casting code. No data will be displayed until a cast is made.
 */

// export const useTimer = (): Timer | undefined => {
//   const maybeTimer = useTopic(channel, "instructor_says") // instructor_says can have various types
//   return unwrapTimer(maybeTimer)
// }
export function* waitForSocketConnection() {
  while (!socket?.isConnected()) {
    yield delay(400)
  }
  return socket
}

/**
 * This is just setting up the channel and joining the channel definition.
 * If this fails we will reconnect and try again.
 *
 * @param socket The phoenix websocket.
 * @param channelDef A channel definition, e.g. class:<id>
 */
export function connectToChannel(
  socket: Socket,
  channelDef: string,
  params?: any, // FIXME type classChannelParams, etc.
): Promise<Channel> {
  // Setting the update rate parameter is required to recieve class stats messages.
  // See commons/elixir for details.
  return new Promise((resolve, reject) => {
    try {
      const channel = socket.channel(channelDef, params)
      if (channel === undefined) {
        return
      }
      channel
        .join()
        .receive("ok", () => resolve(channel))
        .receive("error", (e) => reject(channelDef + " " + e))
        .receive("timeout", () => reject(channelDef + " timeout"))
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * A simple Saga wrapper of continous events from a phoenix channel.
 *
 * @param channel The phoenix channel we are setting up our listener on.
 * @param event The event we are listening for.
 */
export function monitorChannelEvents(
  channel: Channel,
  event: string,
): EventChannel<ChannelMessageType> {
  return eventChannel((emit) => {
    channel.on(event, (msg) => {
      emit({ event, msg })
    })
    channel.onClose(() => {
      emit(END)
    })
    return () => {
      channel.off(event)
    }
  })
}

export type ChannelMessageType = {
  event: string
  msg: any
}

/**
 * We are setting up an event channel to listen for new messages.
 * This will not close during normal operation. We might leave the
 * channel all together but not cancel an channel.on listener.
 *
 * @param phoenixChannel The Phoenix channel we use for receiving messages.
 * @param event The event we are listening for on the channel.
 * @param actionCreator
 */
export function* waitForMessages(
  phoenixChannel: Channel,
  event: string,
  actionCreator: (a: any) => any,
  endedAction?: () => any,
) {
  const msgChannel = yield call(monitorChannelEvents, phoenixChannel, event)
  try {
    while (true) {
      const channelMessage = yield take(msgChannel)
      yield put(actionCreator(channelMessage.msg) as Action<any>)
    }
  } catch (error) {
  } finally {
    if (yield cancelled()) {
      msgChannel.close()
      if (endedAction !== undefined) {
        yield put(endedAction() as Action<any>)
      }
    }
  }
}
