import "./Countdown.scss"
import React, { useEffect, useState } from "react"
import moment from "moment"
import { useT } from "@transifex/react"

const Countdown = ({ streamStartTime, color }) => {
  const t = useT()

  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const startTime = moment(streamStartTime)
      const duration = moment.duration(startTime.diff())
      setHours(Math.floor(duration.asHours()))
      setMinutes(duration.minutes())
      setSeconds(duration.seconds())
    }, 1000)

    return () => clearInterval(interval)
  }, [streamStartTime])

  const formatNumber = (number) => {
    number = number < 0 ? 0 : number
    return number < 10 ? "0" + number : number.toString()
  }

  return (
    <div className="Countdown">
      <div className="CountdownSegment start">
        <div className="Value" style={{ color }}>
          {formatNumber(hours)}
        </div>
        <div className="Label" style={{ color }}>
          {t("hours")}
        </div>
      </div>
      <div className="CountdownSegment">
        <div className="Value" style={{ color }}>
          {formatNumber(minutes)}
        </div>
        <div className="Label" style={{ color }}>
          {t("minutes")}
        </div>
      </div>
      <div className="CountdownSegment end">
        <div className="Value" style={{ color }}>
          {formatNumber(seconds)}
        </div>
        <div className="Label" style={{ color }}>
          {t("seconds")}
        </div>
      </div>
    </div>
  )
}

export default Countdown
