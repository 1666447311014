import { connect } from "react-redux"
import actions from "duck/actions"
import CountdownTimer from "components/CountdownTimer"

const mapStateToProps = (state) => ({
  shouldShow: state.instructor.countdownTimer.shouldShow,
  expire_time: state.instructor.countdownTimer.expire_time,
  color: state.instructor.countdownTimer.color,
})
const mapDispatchToProps = (dispatch) => {
  return {
    endCountdown: () => {
      dispatch(actions.instructor.endCountdown())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CountdownTimer)
