import React from "react"
import "./BottomContainer.scss"
import DisplayToken from "components/DisplayToken"
import { MotifPicker } from "components/MotifPicker/MotifPicker"

export const BottomContainer = () => {
  return (
    <div className="BottomContainer">
      <DisplayToken />
      <MotifPicker />
    </div>
  )
}
