import { useT } from "@transifex/react"
import { Motif } from "models/Motif"
import { Stream } from "models/Stream"
import moment, { Moment } from "moment"

export const defaultMotif = "english_only"

export const useMotifs = (): Motif[] => {
  const t = useT()
  return [
    {
      id: "english_only",
      name: "English only",
      description: t("Shows only English Motosumo classes"),
    },
    {
      id: "spanish_only",
      name: "Solo en español",
      description: t("Shows only Spanish Motosumo classes"),
    },
    {
      id: "portuguese_only",
      name: "Apenas em português",
      description: t("Shows only Portuguese Motosumo classes"),
    },
    {
      id: "spanish_and_english",
      name: "Español e ingles",
      description: t(
        "Shows primarily Spanish Motosumo classes but fills in gaps with English classes when possible.",
      ),
    },
    {
      id: "portuguese_and_english",
      name: "Português e Inglês",
      description: t(
        "Shows primarily Portuguese Motosumo classes but fills in gaps with English classes when possible.",
      ),
    },
  ]
}

export const selectStreamForMotifAndDate = (
  streams: Stream[],
  motif: string,
  now: Moment,
): Stream | undefined => {
  let primaryLocale: string
  let secondaryLocale: string | undefined
  switch (motif) {
    case "english_only":
      primaryLocale = "en_US"
      break
    case "spanish_only":
      primaryLocale = "es_419"
      break
    case "portuguese_only":
      primaryLocale = "pt_BR"
      break
    case "spanish_and_english":
      primaryLocale = "es_419"
      secondaryLocale = "en_US"
      break
    case "portuguese_and_english":
      primaryLocale = "pt_BR"
      secondaryLocale = "en_US"
      break
    default:
      throw new Error("Unexpected motif: " + motif)
  }
  return searchForStream(streams, primaryLocale, secondaryLocale, now)
}

// The following code assumes that streams with the same workout locale do not
// overlap, and may give inaccurate results if that assumption is broken. This
// assumption is currently guaranteed by the Experience Team. The code also
// assumes the list of streams is ordered by start time, and will give wildly
// inaccurate results in _that_ assumption is broken. This assumption is
// guaranteed by the management service.
const searchForStream = (
  streams: Stream[],
  primaryLocale: string,
  secondaryLocale: string | undefined,
  now: Moment,
) => {
  for (let index = 0; index < streams.length; index++) {
    const stream = streams[index]
    // Skip streams that are in the past
    if (
      moment(stream.start_utc_iso_datetime)
        .add(stream.duration_s, "seconds")
        .isBefore(now)
    ) {
      continue
    }
    // Pick a stream in the primary locale regardless of overlap
    if (stream.workout_locale === primaryLocale) {
      return stream
    }
    // Otherwise pick a stream in the secondary locale if it has no overlap
    if (
      stream.workout_locale === secondaryLocale &&
      !hasOverlap(streams, index, primaryLocale)
    ) {
      return stream
    }
  }
}

const hasOverlap = (
  streams: Stream[],
  index: number,
  primaryLocale: string,
) => {
  const stream = streams[index]
  // First look for overlap in the backward direction
  for (let backward = index - 1; backward >= 0; backward--) {
    const beforeStream = streams[backward]
    // Only look for overlap with streams in the primary locale, skip all others
    if (beforeStream.workout_locale !== primaryLocale) {
      continue
    }
    // Check whether the before stream ends after our start time
    if (
      moment(beforeStream.start_utc_iso_datetime)
        .add(beforeStream.duration_s, "seconds")
        .isAfter(stream.start_utc_iso_datetime)
    ) {
      // We found overlap
      return true
    } else {
      // No overlap in the backward direction
      break
    }
  }
  // Then look for overlap in the forward direction (structurally identical)
  for (let forward = index + 1; forward < streams.length; forward++) {
    const afterStream = streams[forward]
    if (afterStream.workout_locale !== primaryLocale) {
      continue
    }
    if (
      moment(stream.start_utc_iso_datetime)
        .add(stream.duration_s, "seconds")
        .isAfter(afterStream.start_utc_iso_datetime)
    ) {
      return true
    } else {
      break
    }
  }
  // No overlap in either direction
  return false
}
