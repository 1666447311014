const emojiList = [
  "train_for_schools",
  "train_for_brains",
  "save_the_children",
  "pink_ribbon",
  "birthday_cake",
  "crown",
  "dart",
  "dashing_away",
  "exploding_head",
  "fire",
  "grinning_face",
  "hot_face",
  "muscle",
  "partying_face",
  "peace_symbol",
  "person_biking",
  "punch",
  "rainbow_flag",
  "red_heart",
  "rocket",
  "rofl",
  "sign_of_the_horns",
  "sos_button",
  "star_struck",
  "superhero",
  "sweat_drops",
  "tada",
  "thumbsup",
  "trophy",
  "waving_hand",
  "zany_face",
]

function isExisting(emoji) {
  return emojiList.includes(emoji)
}

export default { isExisting }
