import { createSelector } from "reselect"
import { getZoneColor } from "tools/zones"

const getZones = (state) => state.trainingZones
const getUsers = (state) => state.users

export const getStats = createSelector([getUsers], (users) => {
  return users?.stats
})

/**
 * Should return an object like:
 * {
 *  list: [ ... emoji ... ]
 * }
 * @param {*} state
 */
export const getMessages = (state = null) => {
  if (state && state.users) {
    return Object.values(state.users.messages)
      .sort(compareUserEmoji)
      .map((e) => {
        const user = state.users.stats[e.user_id]
        e["nickname"] = user ? user.nickname : "..."
        return e
      })
  } else {
    return null
  }
}

export const compareUserEmoji = (a, b) => {
  return b.created - a.created
}

export const getEmojis = (state = null) => {
  if (state && state.users) {
    return state.users.emojis
  }
}

export const getLoggedInStats = createSelector([getStats], (stats) => {
  return undefined
})

/**
 * Selector that gets the cadence of the logged in user
 * @return {Number}      The current cadence
 */
export const getLoggedInCadence = createSelector(
  [getLoggedInStats],
  (stats) => {
    return stats?.cadence
  },
)

/**
 * Selector that gets the distance of the logged in user in miles
 * @return {Number}      The total distance in miles
 */
export const getLoggedInDistance = createSelector(
  [getLoggedInStats],
  (stats) => {
    return stats?.total_distance / 1609.344 || 0
  },
)

/**
 * Selector that gets the calories burned of the logged in user
 * @return {Number}      calories in kcal
 */
export const getLoggedInCalories = createSelector(
  [getLoggedInStats],
  (stats) => {
    return stats?.total_calories || 0
  },
)

/**
 * Selector that gets current heart rate of the logged in user
 * @return {Number}      heart rate in BPM
 */
export const getLoggedInHeartRate = createSelector(
  [getLoggedInStats],
  (stats) => {
    return stats?.heartrate
  },
)

/**
 * Selector that gets current heart rate in percent of max of the logged in user
 * @return {Number}      heart rate in percent of max
 */
export const getLoggedInHeartRatePercent = createSelector(
  [getLoggedInStats],
  (stats) => {
    return stats?.heartrate_percent
  },
)

/**
 * Selector that gets current power in percent of FTP of the logged in user
 * @return {Number}      power in percent of FTP
 */
export const getLoggedInPowerPercent = createSelector(
  [getLoggedInStats],
  (stats) => {
    return stats?.power_percent
  },
)

/**
 * Selector that gets current cadence zone color of the logged in user
 * @return {Number}      cadence zone color
 */
export const getLoggedInCadenceZoneColor = createSelector(
  [getLoggedInCadence, getZones],
  (cadence, zones) => {
    return getZoneColor(cadence, zones?.cadence?.zones)
  },
)

/**
 * Selector that gets current heart rate zone color of the logged in user
 * @return {String}      heart rate zone color
 */
export const getLoggedInHeartRateZoneColor = createSelector(
  [getLoggedInHeartRatePercent, getZones],
  (heartratePercent, zones) => {
    return getZoneColor(heartratePercent, zones?.heartrate?.zones)
  },
)

/**
 * Selector that gets current power (FTP) zone color of the logged in user
 * @return {String}      power zone color
 */
export const getLoggedInPowerZoneColor = createSelector(
  [getLoggedInPowerPercent, getZones],
  (powerPercent, zones) => {
    return getZoneColor(powerPercent, zones?.power?.zones)
  },
)

/**
 * Selector that gets the cadence meter value. Copied from the mobile app.
 * @return {Number}      cadence meter value (0 - 1)
 */
export const getLoggedInCadenceMeterValue = createSelector(
  [getLoggedInCadence],
  (cadence) => {
    return (cadence - 40) / 100
  },
)

/**
 * Selector that gets the heart rate meter value. Copied from the mobile app.
 * @return {Number}      heart rate meter value (0 - 1)
 */
export const getLoggedInHeartRateMeterValue = createSelector(
  [getLoggedInHeartRatePercent],
  (heartRatePercent) => {
    const min = 60
    const max = 100
    const value = (heartRatePercent - min) / (max - min)
    return Math.max(value, 0)
  },
)

/**
 * Selector that gets the power meter value. Copied from the mobile app.
 * @return {Number}      power meter value
 */
export const getLoggedInPowerMeterValue = createSelector(
  [getLoggedInPowerPercent, getZones],
  (powerPercent, zones) => {
    const min = 50
    const max = 125
    const value = (powerPercent - min) / (max - min)
    return Math.max(value, 0)
  },
)

export default {
  getStats,
  getEmojis,
  getMessages,
  compareUserEmoji,
  getLoggedInStats,
  getLoggedInCadence,
  getLoggedInDistance,
  getLoggedInCalories,
  getLoggedInHeartRate,
  getLoggedInHeartRatePercent,
  getLoggedInPowerPercent,
  getLoggedInCadenceZoneColor,
  getLoggedInHeartRateZoneColor,
  getLoggedInPowerZoneColor,
  getLoggedInCadenceMeterValue,
  getLoggedInHeartRateMeterValue,
  getLoggedInPowerMeterValue,
}
