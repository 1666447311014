import React, { useEffect, useState } from "react"
import { bind } from "mousetrap"
import {
  getClassStats,
  getIsCasting,
  getModeDisplayValue,
  getModeIndex,
} from "modules/device/selectors"
import { useSelector } from "react-redux"
import Cell from "./Cell"
import { getEmojis } from "modules/users/duck/selectors"
import { defaultCadenceZones, getZoneColor } from "tools/zones"
import { useT } from "@transifex/react"
import "./Grid.scss"

const MAX_NUMBER_OF_USERS = 104

export const Grid = () => {
  const t = useT()
  const isCasting = useSelector(getIsCasting)
  const [hasLiveStream, setHasLiveStream] = useState(false)
  const [className, setClassName] = useState(`Grid Grid--1`)
  const classStats = useSelector(getClassStats)
  const modeIndex = useSelector(getModeIndex)
  const userEmojis = useSelector(getEmojis)
  const modeDisplayValue = useSelector(getModeDisplayValue)

  const userStats = classStats?.user_stats
  const numberOfUsers = userStats?.length ?? 1

  let modeUnit
  switch (modeIndex) {
    default:
    case 0:
      modeUnit = t("RPM")
      break
    case 1: // Heart rate mode
      modeUnit = "% HR"
      break
    case 2: // Power mode
      modeUnit = "% FTP"
      break
  }

  bind("command+shift+alt+s", () => {
    setHasLiveStream(!hasLiveStream)
  })

  useEffect(() => {
    setHasLiveStream(!isCasting)
  }, [isCasting])

  useEffect(() => {
    if (hasLiveStream) {
      setClassName(`Grid-LSM Grid-LSM--${numberOfUsers}`)
    } else {
      setClassName(`Grid Grid--${numberOfUsers}`)
    }
  }, [hasLiveStream, numberOfUsers])

  if (userStats === undefined) {
    return null
  }

  return (
    <section className={className}>
      {userStats.slice(0, MAX_NUMBER_OF_USERS).map((user) => (
        <Cell
          key={user.user_id}
          zone={getZoneColor(user[modeDisplayValue], defaultCadenceZones)}
          nickname={user.nickname}
          value={user[modeDisplayValue]}
          unit={modeUnit}
          calories={Math.round(user.total_calories)}
          user_id={user.user_id}
          is_active={user.is_active}
          has_power={user.has_power}
          has_heartrate={user.has_heartrate}
          currentUserNumber={numberOfUsers}
          userEmoji={userEmojis ? userEmojis[user.user_id] : undefined}
        />
      ))}
    </section>
  )
}
