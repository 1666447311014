import _ from "lodash"

/**
 * if the value if below the minimum value of the zone system,
 * then we use default color
 */
const defaultColor = "#3e4d54"

const defaultPowerZones = [
  {
    id: 1,
    name: "Active Recovery",
    boundary: {
      min: 50,
      max: 55.5,
    },
    color: "gray",
    color_code: "#aaaaaa",
  },
  {
    id: 2,
    name: "Endurance",
    boundary: {
      min: 55.5,
      max: 76.5,
    },
    color: "blue",
    color_code: "#0cb3d2",
  },
  {
    id: 3,
    name: "Tempo",
    boundary: {
      min: 76.5,
      max: 90.5,
    },
    color: "green",
    color_code: "#28ce53",
  },
  {
    id: 4,
    name: "Lactate Threshold",
    boundary: {
      min: 90.5,
      max: 105.5,
    },
    color: "yellow",
    color_code: "#ffd831",
  },
  {
    id: 5,
    name: "VO2 Max",
    boundary: {
      min: 105.5,
      max: 10000000,
    },
    color: "red",
    color_code: "#d42842",
  },
]

const defaultHeartrateZones = [
  {
    id: 1,
    name: "Active Recovery",
    boundary: {
      min: 61,
      max: 70.5,
    },
    color: "gray",
    color_code: "#aaaaaa",
  },
  {
    id: 2,
    name: "Endurance",
    boundary: {
      min: 70.5,
      max: 76.5,
    },
    color: "blue",
    color_code: "#0cb3d2",
  },
  {
    id: 3,
    name: "Tempo",
    boundary: {
      min: 76.5,
      max: 82.5,
    },
    color: "green",
    color_code: "#28ce53",
  },
  {
    id: 4,
    name: "Lactate Threshold",
    boundary: {
      min: 82.5,
      max: 90.5,
    },
    color: "yellow",
    color_code: "#ffd831",
  },
  {
    id: 5,
    name: "VO2 Max",
    boundary: {
      min: 90.5,
      max: 10000000,
    },
    color: "red",
    color_code: "#d42842",
  },
]

const defaultCadenceZones = [
  {
    id: 1,
    name: "Slow",
    boundary: {
      min: 5,
      max: 50,
    },
    color: "gray",
    color_code: "#aaaaaa",
  },
  {
    id: 2,
    name: "Strength",
    boundary: {
      min: 50,
      max: 70,
    },
    color: "blue",
    color_code: "#0cb3d2",
  },
  {
    id: 3,
    name: "Endurance",
    boundary: {
      min: 70,
      max: 90,
    },
    color: "green",
    color_code: "#28ce53",
  },
  {
    id: 4,
    name: "Cardio",
    boundary: {
      min: 90,
      max: 110,
    },
    color: "yellow",
    color_code: "#ffd831",
  },
  {
    id: 5,
    name: "Sprint",
    boundary: {
      min: 110,
      max: 10000000,
    },
    color: "red",
    color_code: "#d42842",
  },
]

/**
 * given a zone value and zone definitions @returns zone color
 * @param {number} value
 * @param {{}} zones
 */
const getZoneColor = (value, zones) => {
  let color = defaultColor
  const _zones = _.values(zones)

  for (let i = 0; i < _zones.length; i++) {
    if (value >= zones[i].boundary.min && value < zones[i].boundary.max) {
      color = _zones[i].color_code
      break
    }
  }
  return color
}

const cadenceToZoneEffort = (cadence?: number): string => {
  if (cadence === undefined) return "not_measured"
  if (cadence < 5.0) return "not_measured"
  if (cadence < 50.0) return "very_slow"
  if (cadence < 70.0) return "slow"
  if (cadence < 90.0) return "moderate"
  if (cadence < 110.0) return "fast"
  return "very_fast"
}

export {
  getZoneColor,
  cadenceToZoneEffort,
  defaultCadenceZones,
  defaultHeartrateZones,
  defaultPowerZones,
}
