import React from "react"
import { timeUntil, secondsUntil, secondsToTimeString } from "tools/time"

import CountdownIcon from "./CountdownIcon"
import "./CountdownTimer.scss"
export default class CountdownTimer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      remaining: 0,
      leave: true,
    }
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, 50)
  }
  componentWillUnmount() {
    clearInterval(this.timer)
  }

  tick = () => {
    let remaining = timeUntil(this.props.expire_time)
    let leave = remaining < 0
    this.setState({ remaining, leave })
  }

  render() {
    let seconds = secondsToTimeString(
      secondsUntil(this.props.expire_time),
      true,
    )
    if (this.props.shouldShow && this.state.remaining > -500) {
      return (
        <div
          className={`CountdownTimer ${
            this.state.leave ? "CountdownTimer__leave" : ""
          }`}
          style={{
            backgroundColor: this.props.color,
          }}
        >
          <CountdownIcon showIcon={this.props.showIcon} />
          <span className="CountdownTimer__value">{seconds}</span>
          <span className="CountdownTimer__seconds">{`${
            seconds === 1 ? "second" : "seconds"
          }`}</span>
        </div>
      )
    } else {
      return null
    }
  }
}
