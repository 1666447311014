import axios from "axios"
import { Stream } from "models/Stream"

export const provider = axios.create({
  baseURL: process.env.REACT_APP_MGMT_SVC_URL,
  timeout: 5500, // Everything above 5 seconds is pretty bad.
})

/**
 * This will get the static file served by amplify.
 */
function get_version() {
  const config = {
    headers: { "Cache-Control": "no-cache" },
  }
  return axios
    .get("/version.txt", config)
    .then((response) => response.data.trim())
}

async function suggestLocale(): Promise<string> {
  const response = await provider.get("/suggest_locale")
  return response.data.suggested_locale
}

function getStreams() {
  return provider
    .get("/stream/public")
    .then((response) => response.data.streams as Stream[])
}

function restTimeSync() {
  const config = {
    headers: { Accept: "application/json" },
  }
  const tsRequest = { sent_time: Date.now() }
  return provider.post("/time_sync", tsRequest, config).catch((error) => {
    /* Ignore errors */
  })
}

function reportCheckbuild(
  branch,
  local_version,
  server_version,
  reload,
  selected_motif,
) {
  /**
   * Allows the server to check what build number is running.
   *
   * This method simply calls a "log" endpoint to report in.
   */
  const config = {
    params: {
      type: "td_build",
      origin: window.location.hostname,
      branch,
      local_version,
      server_version,
      reload,
      selected_motif,
    },
  }
  return provider.get("/log", config)
}

export default {
  suggestLocale,
  getStreams,
  restTimeSync,
  reportCheckbuild,
  get_version,
}
