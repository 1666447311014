import { createReducer } from "@reduxjs/toolkit"
import { Flags, initialFlags } from "./flags"
import { setFlagsAction } from "./flagsActions"

export type FlagsState = Flags

const initialState: FlagsState = initialFlags

export const flagsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setFlagsAction, (state, action) => {
    return action.payload
  })
})
