import "./MotifPicker.scss"
import React from "react"
import { useT } from "@transifex/react"
import { useDispatch } from "react-redux"
import { setMotifPickerModalOpen } from "modules/ui/uiSlice"
import caret from "assets/images/down-arrow.png"
import { useSelector } from "react-redux"
import { getSelectedMotif } from "modules/motif/motifSelectors"
import { useMotifs } from "modules/motif/motifs"
import { getFlags } from "flags/flagsSelectors"
import { getIsCasting } from "modules/device/selectors"

export const MotifPicker = () => {
  const t = useT()
  const flags = useSelector(getFlags)
  const motifs = useMotifs()
  const selectedMotif = useSelector(getSelectedMotif)
  const isCasting = useSelector(getIsCasting)
  const dispatch = useDispatch()
  const openMotifPickerModal = () => dispatch(setMotifPickerModalOpen(true))

  const getMotifName = () => {
    return motifs.find((motif) => motif.id === selectedMotif)?.name
  }

  if (isCasting) {
    return null
  }

  return (
    <div className="MotifPicker">
      <span>{t("Select channel")}:</span>
      <span className="motif" onClick={openMotifPickerModal}>
        {getMotifName()}
        <img className="caret" src={caret} alt="" />
      </span>
    </div>
  )
}
