import React, { Component, Fragment } from "react"

class Nickname extends Component {
  render() {
    return (
      <Fragment>
        <div className="Cell__nicknameContainer__nickname">
          {this.props.nickname || "<nickname>"}
        </div>
      </Fragment>
    )
  }
}

export default Nickname
