import "./DisplayToken.scss"
import React from "react"
import { useSelector } from "react-redux"
import { useT } from "@transifex/react"
import { getDeviceToken } from "modules/device/selectors"

const DisplayToken = () => {
  const t = useT()
  const token = useSelector(getDeviceToken)

  if (token === undefined || token.length === 0) {
    return null
  }

  return (
    <div className="DisplayToken">
      <span>{t("Team Display code")}:</span>
      <span className="token">{token.toUpperCase()}</span>
    </div>
  )
}

export default DisplayToken
