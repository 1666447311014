import React, { Fragment, useEffect, useRef } from "react"
import "./RacingGame3D.scss"
import {
  UNITY_CONTENT,
  unityResetGame,
  unitySendGameConfig,
  unitySendGameData,
  unitySendGameResults,
  useGameIsReady,
} from "./UnityContent"
import Unity from "react-unity-webgl"
import { useDispatch } from "react-redux"
import { gameEndedAction } from "modules/game/gameSlice"
import { useSelector } from "react-redux"
import {
  gameAvailableSelector,
  gameConfigSelector,
  gameDataSelector,
  getGameRunningState,
} from "modules/game/gameSelectors"
import { GameRunningState } from "modules/game/gameTypes"

// docs: https://react-unity-webgl.dev/docs/7.x.x/introduction

// If the game has not ended ("ended" message received from the statsworker)
// within this timeout, we end the game manually to ensure we won't get stuck
// on the game results screen, and go back to the data screen.
const GAME_FINISHED_TIMEOUT = 30000

export const RacingGame3D = () => {
  const dispatch = useDispatch()
  const gameIsReady = useGameIsReady()
  const gameConfig = useSelector(gameConfigSelector)
  const gameData = useSelector(gameDataSelector)
  const gameRunningState = useSelector(getGameRunningState)

  const gameAvailable = useSelector(gameAvailableSelector)

  const gameFinishedTimeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (gameIsReady && gameConfig !== undefined) {
      unitySendGameConfig(gameConfig)
    }
  }, [dispatch, gameIsReady, gameConfig])

  useEffect(() => {
    if (gameRunningState === GameRunningState.Running) {
      unitySendGameData(gameData)
    }
  }, [gameRunningState, gameData])

  useEffect(() => {
    if (gameRunningState === GameRunningState.Finished) {
      unitySendGameResults(gameData)
      gameFinishedTimeoutRef.current = setTimeout(() => {
        dispatch(gameEndedAction())
      }, GAME_FINISHED_TIMEOUT)
    }
  }, [dispatch, gameData, gameRunningState])

  // Since the RacingGame3D is always mounted, we can't rely on the useEffect
  // return value to clear the game finished timeout. Instead we clear it when the
  // game state is initialized.
  useEffect(() => {
    if (
      gameRunningState === GameRunningState.None &&
      gameFinishedTimeoutRef.current !== undefined
    ) {
      clearTimeout(gameFinishedTimeoutRef.current)
      gameFinishedTimeoutRef.current = undefined
      unityResetGame()
    }
  }, [gameRunningState])

  return (
    <Fragment>
      <div
        className="UnityGameScreen"
        style={{
          visibility: gameAvailable ? "visible" : "hidden",
        }}
      >
        <Unity unityContent={UNITY_CONTENT} />
      </div>
    </Fragment>
  )
}
