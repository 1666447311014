import React from "react"
import CountdownTimer from "containers/CountdownTimer"
import InstructorEmojiMessage from "containers/EmojiMessage/InstructorEmojiMessage"
import "./DataScreen.scss"
import { DataScreenBody } from "components/UserGrid/DataScreenBody"
import { Header } from "components/Header"
import ModeBox from "components/ModeBox/ModeBox"

export const DataScreen = () => {
  return (
    <div className="DataScreen">
      <Header />
      <DataScreenBody />
      <CountdownTimer showIcon={true} />
      <InstructorEmojiMessage />
      <ModeBox />
    </div>
  )
}
