import React from "react"
import { createRoot } from "react-dom/client"
import BrowserCompatGate from "./containers/BrowserCompatGate"
import { Provider } from "react-redux"
import { App } from "components/App/App"
import store, { persister } from "./state/store"
import { PersistGate } from "redux-persist/integration/react"
import { QueryClient, QueryClientProvider } from "react-query"
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill"
import "./scss/_base.scss"
import "./scss/_typography.scss"

const queryClient = new QueryClient()

polyfillCountryFlagEmojis()

const domNode = document.getElementById("root")
const root = createRoot(domNode)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserCompatGate>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserCompatGate>
    </PersistGate>
  </Provider>,
)
