import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ReducerWithInitialState } from "@reduxjs/toolkit/dist/createReducer"
import { ClassStats } from "models/ClassStats"
import { DeviceModeUpdate, modeToIndex } from "models/DeviceMode"
import { DeviceToken } from "models/DeviceToken"
import { GroupInfo } from "models/GroupInfo"

export interface DeviceState {
  device_token: string
  group_id?: string
  group_code?: number
  group_name?: string
  class_stats?: ClassStats
  start_time?: string
  duration?: number
  mode_index: number
}

const initialState: DeviceState = {
  device_token: "",
  group_id: undefined,
  group_code: undefined,
  group_name: undefined,
  class_stats: undefined,
  start_time: undefined,
  duration: undefined,
  mode_index: 0,
}

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    joinGroupAction() {},
    leaveGroupAction() {},
    castGroupAction(state, action: PayloadAction<GroupInfo>) {
      return state
    },
    startCastingAction(state, action: PayloadAction<GroupInfo>) {
      state.group_id = action.payload.group_id
      state.group_code = action.payload.group_code
      state.group_name = action.payload.group_name
      state.start_time = action.payload.start_time
      state.duration = action.payload.duration
    },
    clearGroupAction(state) {
      // This is dispatched When the device channel closes, in which case
      // we don't have any device information.
      return initialState
    },
    newDeviceTokenAction(state, action: PayloadAction<DeviceToken>) {
      state.device_token = action.payload.device_token
    },
    classStatsAction(state, action: PayloadAction<ClassStats>) {
      state.class_stats = action.payload
    },
    classStoppedAction(state) {
      // This is dispatched when either the casting user or the stream display
      // closes the class. We clear everything except the device_token.
      return { ...initialState, device_token: state.device_token }
    },
    clearClassStatsAction(state) {
      state.class_stats = undefined
    },
    deviceEndAction() {
      // This is never actually dispatched.
    },
    modeUpdateAction(state, action: PayloadAction<DeviceModeUpdate>) {
      state.mode_index = modeToIndex(action.payload.mode)
    },
  },
})

export const {
  joinGroupAction,
  leaveGroupAction,
  castGroupAction,
  startCastingAction,
  clearGroupAction,
  newDeviceTokenAction,
  classStatsAction,
  classStoppedAction,
  clearClassStatsAction,
  deviceEndAction,
  modeUpdateAction,
} = deviceSlice.actions

export const deviceReducer =
  deviceSlice.reducer as ReducerWithInitialState<DeviceState>

deviceReducer.getInitialState = () => initialState
